import { useState, useEffect } from 'react';
import { Button, Modal } from 'flowbite-react';
import {
  ExclamationTriangleIcon,
  BackwardIcon,
  ForwardIcon,
  DocumentPlusIcon,
} from '@heroicons/react/24/solid';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../common/ToastContext';
import AddBarangayApplicationStep1 from './add_barangay_application_steps/AddBarangayApplicationStep1';
import AddBarangayApplicationStep2 from './add_barangay_application_steps/AddBarangayApplicationStep2';
import AddBarangayApplicationStep3 from './add_barangay_application_steps/AddBarangayApplicationStep3';
import api from '../../common/api';

export interface BarangayApplicationFormData {
  applicationType: string;
  assistanceCategory: string;
  purpose: string;
  files: { [key: string]: File };
}

const initialApplicationFormData: BarangayApplicationFormData = {
  applicationType: '',
  assistanceCategory: '',
  purpose: '',
  files: {},
};

function validateApplicationFormData(
  data: BarangayApplicationFormData,
): boolean {
  if (!data.applicationType || !data.assistanceCategory || !data.purpose) {
    return false;
  }

  return true;
}

interface AddBarangayApplicationModalProps {
  show: boolean;
  onDismiss: () => void;
  onFormSubmitSuccess: () => void;
  onFormSubmitFailure: () => void;
}

export default function AddBarangayApplicationModal(
  props: AddBarangayApplicationModalProps,
) {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [isloading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(props.show);
  const [currentStep, setCurrentStep] = useState(1);
  const [showConfirmDocumentModal, setShowConfirmDocumentModal] =
    useState(false);
  const [applicationFormData, setApplicationFormData] =
    useState<BarangayApplicationFormData>(initialApplicationFormData);
  const totalSteps = 4;

  useEffect(() => {
    setShowModal(props.show);
  }, [props.show]);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = e.target;
    setApplicationFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileInputChange = (
    key: string,
    event: React.ChangeEvent<HTMLInputElement>,
    formData: BarangayApplicationFormData,
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setApplicationFormData({
        ...formData,
        files: {
          ...formData.files,
          [key]: files[0],
        },
      });
    }
  };

  const handleFileDelete = (key: string) => {
    setApplicationFormData((prevState) => {
      const updatedFiles = { ...prevState.files };
      delete updatedFiles[key];
      return {
        ...prevState,
        files: updatedFiles,
      };
    });
  };

  const handleDeleteAllFiles = () => {
    setApplicationFormData((prevState) => ({
      ...prevState,
      files: {},
    }));
  };

  const nextStep = () => {
    if (currentStep < totalSteps) {
      if (
        currentStep === 1 &&
        !validateApplicationFormData(applicationFormData)
      ) {
        alert('Please complete the application information');
        return;
      }
      if (currentStep === 3) {
        submitForm();
        return;
      }
      setCurrentStep((prev) => prev + 1);
    }
  };

  const prevStep = (bypass: boolean = false) => {
    if (currentStep >= 2) {
      if (!bypass) {
        setShowConfirmDocumentModal(true);
        return;
      }
      handleDeleteAllFiles();
    }
    setShowConfirmDocumentModal(false);
    setCurrentStep((prev) => prev - 1);
  };

  const submitForm = async () => {
    const formData = new FormData();
    formData.append('application_type', applicationFormData.applicationType);
    formData.append(
      'assistance_category',
      applicationFormData.assistanceCategory,
    );
    formData.append('purpose', applicationFormData.purpose);

    Object.keys(applicationFormData.files).forEach((key) => {
      formData.append('document_files', applicationFormData.files[key]);
      formData.append('document_types', key);
    });

    console.log(Array.from(formData.entries()));

    try {
      setIsLoading(true);
      await api.post('/barangay/application', formData);
      setIsLoading(false);
      showToast('success', 'Application created successfully!');
      setApplicationFormData(initialApplicationFormData);
      setCurrentStep(1);
      props.onFormSubmitSuccess();
    } catch (error: any) {
      console.log(error);
      if (error.response?.status === 401 || false) {
        showToast('error', 'Session timed out, please login again!');
        navigate('/logout');
        return;
      }
      showToast(
        'error',
        `Error creating application: ${
          error.response?.data.detail[0].msg ||
          error.response?.data.detail ||
          'Unknown error'
        }`,
      );
      setApplicationFormData(initialApplicationFormData);
      setIsLoading(false);
      setCurrentStep(1);
      props.onFormSubmitFailure();
    }
  };

  const handleDismiss = () => {
    setCurrentStep(1);
    props.onDismiss();
  };

  return (
    <Modal show={showModal} size="5xl" onClose={handleDismiss} popup>
      <Modal.Header className="px-6 pt-4">
        Apply for Barangay Assistance
      </Modal.Header>
      <Modal.Body>
        <div className="container mx-auto mt-4 lg:mt-8">
          <div className="flex flex-col space-y-2 md:flex-row md:items-center md:justify-between md:space-y-0">
            <div className="flex flex-row space-x-2">
              <CheckBadgeIcon
                className={`size-5 fill-blue-600 ${
                  currentStep > 1 ? 'flex' : 'hidden'
                }`}
              />
              <p
                className={`text-base font-medium leading-tight ${
                  currentStep > 1 ? 'text-blue-600' : 'dark:text-white'
                }`}
              >
                Information
              </p>
            </div>
            <hr
              className={`mx-4 hidden grow border-t md:flex ${
                currentStep > 1 ? 'border-blue-600' : 'border-gray-400'
              } `}
            />
            <div className="flex flex-row space-x-2">
              <CheckBadgeIcon
                className={`size-5 fill-blue-600 ${
                  currentStep > 2 ? 'flex' : 'hidden'
                }`}
              />
              <p
                className={`text-base font-medium leading-tight ${
                  currentStep > 3 ? 'text-blue-600' : 'dark:text-white'
                }`}
              >
                Documents
              </p>
            </div>
            <hr
              className={`mx-4 hidden grow border-t md:flex ${
                currentStep > 3 ? 'border-blue-600' : 'border-gray-400'
              } `}
            />

            <div className="flex flex-row space-x-2">
              <p className="ml-2 text-base font-medium leading-tight dark:text-white md:ml-0">
                Review
              </p>
            </div>
          </div>
          <div className="mt-8">
            {currentStep === 1 && (
              <AddBarangayApplicationStep1
                applicationFormData={applicationFormData}
                handleInputChange={handleInputChange}
              />
            )}
            {currentStep === 2 && (
              <div className="">
                <AddBarangayApplicationStep2
                  applicationFormData={applicationFormData}
                  handleFileInputChange={handleFileInputChange}
                  handleFileDelete={handleFileDelete}
                />
              </div>
            )}
            {currentStep === 3 && (
              <div className="">
                <AddBarangayApplicationStep3
                  applicationFormData={applicationFormData}
                />
              </div>
            )}
          </div>

          <div className="mt-8 flex justify-between">
            <Button
              color="blue"
              onClick={(e: any) => prevStep()}
              disabled={currentStep === 1}
            >
              <BackwardIcon className="mr-2 size-5" />
              Previous
            </Button>
            <Button
              color="blue"
              onClick={nextStep}
              disabled={currentStep === totalSteps}
              isProcessing={isloading}
            >
              {currentStep <= 3 ? 'Next' : 'Submit'}
              {currentStep <= 3 ? (
                <ForwardIcon className="ml-2 size-5" />
              ) : (
                <DocumentPlusIcon className="ml-2 size-5" />
              )}
            </Button>
          </div>
          <Modal
            show={showConfirmDocumentModal}
            size="md"
            onClose={() => setShowConfirmDocumentModal(false)}
            popup
          >
            <Modal.Header />
            <Modal.Body>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
                  <ExclamationTriangleIcon className="size-6 text-red-600"></ExclamationTriangleIcon>
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3
                    className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
                    id="modal-title"
                  >
                    Document requirements will be deleted
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 dark:text-slate-300">
                      Proceeding to go back will result in uploading documents
                      again. Continue?
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-2 flex flex-row justify-center space-x-4 px-6 py-3 lg:justify-end">
                <Button
                  color="gray"
                  onClick={() => setShowConfirmDocumentModal(false)}
                >
                  Cancel
                </Button>
                <Button color="failure" onClick={(e: any) => prevStep(true)}>
                  Continue
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </Modal.Body>
    </Modal>
  );
}
