import { Button, Card, Label, TextInput } from 'flowbite-react';
import { PencilIcon } from '@heroicons/react/24/solid';

export default function UpdatePasswordCard() {
  const handleUpdatePassword = async (e: React.FormEvent) => {
    e.preventDefault();
    alert('Not implemented yet :(');
  };

  return (
    <Card>
      <form className="grid grid-cols-1 gap-6" onSubmit={handleUpdatePassword}>
        <div className="grid content-end">
          <p className="text-base font-bold text-gray-800 dark:text-slate-200">
            Password information
          </p>
        </div>
        <div>
          <Label
            htmlFor="currentPassword"
            value="Current Password"
            className="mb-2"
          />
          <TextInput
            id="currentPassword"
            name="currentPassword"
            type="password"
            required
          />
        </div>
        <div>
          <Label htmlFor="password" value="New Password" className="mb-2" />
          <TextInput id="password" name="password" type="password" required />
        </div>
        <div>
          <Label
            htmlFor="confirmPassword"
            value="Confirm Password"
            className="mb-2"
          />
          <TextInput
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            required
          />
        </div>
        <div className="flex items-center justify-center">
          <Button color="blue" type="submit">
            <PencilIcon className="mr-2 size-5" />
            Update password information
          </Button>
        </div>
      </form>
    </Card>
  );
}
