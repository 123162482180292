import { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import { Button, Card, Spinner } from 'flowbite-react';
import { FolderPlusIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../common/AuthContext';
import { useToast } from '../common/ToastContext';
import AuthLayout from '../layouts/AuthLayout';
import ProfileIncompleteModal from '../components/modals/ProfileIncompleteModal';
import AddBarangayApplicationModal from '../components/modals/AddBarangayApplicationModal';
import BarangayApplicationTable from '../components/tables/BarangayApplicationTableFlowbite';
import { BarangayApplicationDetail } from '../components/tables/BarangayApplicationTableFlowbite';
import { BarangayApplicationApiData } from '../common/interface';
import api from '../common/api';

export default function BarangayApplications() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { userdata, checkProfile } = useAuth();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showApplyModal, setShowApplyModal] = useState(false);
  const [rows, setRows] = useState<BarangayApplicationDetail[]>([]);

  useEffect(() => {
    async function getApplications() {
      setIsLoading(true);
      let apiResponse = { data: [] };
      try {
        apiResponse = await api.get('/barangay/applications');
        const apiData = apiResponse.data as BarangayApplicationApiData[];
        const applications: BarangayApplicationDetail[] = apiData.map(
          (application, index) => ({
            uuid: application.uuid,
            applicationType: application.application_type,
            assistanceCategory: application.assistance_category,
            amount: application.amount ?? -1,
            financialMode: application.financial_mode ?? 'Not set',
            assistanceSource: application.assistance_source ?? 'Not set',
            purpose: application.purpose,
            status: application.status,
            releasedDatetime:
              application.released_at !== null
                ? format(parseISO(application.released_at!), 'MMMM dd, yyyy')
                : '',
            appliedDatetime: format(
              parseISO(application.created_at),
              'MMMM dd, yyyy',
            ),
            applicant: `${application.user.first_name} ${application.user.last_name}`,
          }),
        );
        setRows(applications);
        setIsLoading(false);
      } catch (error: any) {
        console.log(error);
        if (error.response?.status === 401 || false) {
          showToast('error', 'Session timed out, please login again!');
          navigate('/logout');
          return;
        }
        showToast(
          'error',
          `Error retrieving applications: ${
            error.response?.data.detail[0].msg ||
            error.response?.data.detail ||
            'Unknown error'
          }`,
        );
        setIsLoading(false);
      }
    }
    getApplications();
  }, [showToast, navigate]);

  const handleApplyAction = async () => {
    const profileComplete = await checkProfile();
    if (!profileComplete) {
      setShowConfirmModal(true);
      return;
    }
    setShowApplyModal(true);
  };

  return (
    <AuthLayout>
      <div
        style={{ display: isLoading ? 'flex' : 'none' }}
        className="flex size-full items-center justify-center"
      >
        <Spinner size="xl" />
      </div>
      <Card style={{ display: isLoading ? 'none' : 'flex' }} className="w-full">
        <p className="text-lg font-semibold text-gray-900 dark:text-slate-100">
          Barangay Applications
        </p>
        <div className="flex w-full flex-row items-center justify-end">
          <Button
            color="blue"
            style={{
              display: userdata && userdata.role === 'user' ? '' : 'none',
            }}
            onClick={() => handleApplyAction()}
          >
            <FolderPlusIcon className="mr-2 size-5" />
            Create application
          </Button>
        </div>
        <BarangayApplicationTable data={rows} />
      </Card>
      <ProfileIncompleteModal show={showConfirmModal} />
      <AddBarangayApplicationModal
        show={showApplyModal}
        onDismiss={() => setShowApplyModal(false)}
        onFormSubmitSuccess={() => setShowApplyModal(false)}
        onFormSubmitFailure={() => setShowApplyModal(false)}
      />
    </AuthLayout>
  );
}
