import { useState, useEffect } from 'react';
import { Avatar, Navbar, DarkThemeToggle, Dropdown } from 'flowbite-react';
import { ArrowLeftStartOnRectangleIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../common/AuthContext';
import { useTheme } from '../../common/ThemeContext';
import { useToast } from '../../common/ToastContext';
import { titleCase } from '../../common/utils';
import { Notification } from '../popovers/NotificationPopover';
import NotificationButton from '../buttons/NotificationButton';
import api from '../../common/api';
import { getDateTimeAgo } from '../../common/utils';

interface NotificationApiData {
  triggered_by: {
    email: string;
    role: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    profile: string;
  };
  title: string;
  body: string;
  created_at: string;
}

interface AuthNavbarProps {
  onToggleClick: () => void;
}

export default function AuthNavbar(props: AuthNavbarProps) {
  const navigate = useNavigate();
  const { userdata } = useAuth();
  const { toggleTheme } = useTheme();
  const { showToast } = useToast();
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    async function getNotifications() {
      let apiResponse = { data: [] };
      try {
        apiResponse = await api.get('/notifications');
      } catch (error: any) {
        console.log(error);
        if (error.response?.status === 401 || false) {
          showToast('error', 'Session timed out, please login again!');
          navigate('/logout');
          return;
        }
        showToast(
          'error',
          `Error retrieving notifications: ${
            error.response?.data.detail[0].msg ||
            error.response?.data.detail ||
            'Unknown error'
          }`,
        );
      }
      const notifications: Notification[] = [];
      apiResponse.data.forEach(
        (notificationData: NotificationApiData, index: number) => {
          const notification: Notification = {
            profile: notificationData.triggered_by.profile,
            title: notificationData.title,
            body: notificationData.body,
            time: getDateTimeAgo(notificationData.created_at),
          };
          notifications.push(notification);
        },
      );
      setNotifications(notifications);
    }

    getNotifications();
  }, [showToast, navigate]);

  return (
    <Navbar fluid rounded className="border-b-2 border-gray-100">
      <Navbar.Brand onClick={() => navigate('/')}>
        <img src="/logo.png" className="mr-3 h-6 sm:h-9" alt="MSWDash Logo" />
        <span className="self-center whitespace-nowrap text-xl font-bold dark:text-white">
          MSWDash
        </span>
      </Navbar.Brand>
      <div className="flex space-x-1 md:order-2">
        <NotificationButton notifications={notifications} />
        <DarkThemeToggle onClick={() => toggleTheme()} className="mr-5" />
        <Dropdown
          arrowIcon={false}
          inline
          label={
            <Avatar
              placeholderInitials="A"
              alt="User settings"
              img={`/profiles/${userdata?.profile || '1'}.jpg`}
              className="ml-2"
              rounded
            />
          }
        >
          <Dropdown.Header>
            <span className="block text-sm">
              <span className="mr-2 font-semibold">
                [{titleCase(userdata?.role || 'Unknown Role')}]
              </span>
              {userdata?.firstName || 'Unknown'} {userdata?.lastName || 'Name'}{' '}
            </span>
            <span className="block truncate text-sm font-medium">
              {userdata?.email}
            </span>
          </Dropdown.Header>
          <Dropdown.Item onClick={() => navigate('/profile')}>
            Profile
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            icon={ArrowLeftStartOnRectangleIcon}
            onClick={() => navigate('/logout')}
          >
            Sign out
          </Dropdown.Item>
        </Dropdown>
        <Navbar.Toggle onClick={() => props.onToggleClick()} />
      </div>
    </Navbar>
  );
}
