import { useState, useEffect } from 'react';
import { Button, Modal, Label, Select, TextInput } from 'flowbite-react';
import { UserGroupIcon } from '@heroicons/react/24/solid';
import { useToast } from '../../common/ToastContext';
import { useNavigate } from 'react-router-dom';
import api from '../../common/api';

interface ApproveModalProps {
  uuid: string;
  show: boolean;
  onDismiss: () => void;
  onSubmitSuccess: () => void;
  onSubmitFailure: () => void;
}

function ApproveModal(props: ApproveModalProps) {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [showModal, setShowModal] = useState(false);
  const [financialMode, setFinancialMode] = useState('');
  const [assistanceSource, setAssistanceSource] = useState('');
  const [amount, setAmount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setShowModal(props.show);
  }, [props.show]);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputAmount = parseInt(e.target.value);
    if (isNaN(inputAmount)) {
      inputAmount = 0;
    }
    setAmount(inputAmount);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const query = new URLSearchParams();
    query.append('uuid', props.uuid);
    query.append('financial_mode', financialMode);
    query.append('assistance_source', assistanceSource);
    query.append('amount', amount.toString());

    try {
      setIsLoading(true);
      await api.patch(
        `/application/finance/${props.uuid}?uuid=${props.uuid}&financial_mode=${financialMode}&assistance_source=${assistanceSource}&amount=${amount}`,
      );
    } catch (error: any) {
      console.log(error);
      if (error.response?.status === 401 || false) {
        showToast('error', 'Session timed out, please login again!');
        navigate('/logout');
        return;
      }
      showToast(
        'error',
        `Error approving application: ${
          error.response?.data.detail[0].msg ||
          error.response?.data.detail ||
          'Unknown error'
        }`,
      );
      setFinancialMode('');
      setAssistanceSource('');
      setAmount(0);
      setIsLoading(false);
      props.onSubmitFailure();
      return;
    }
    try {
      await api.patch(`/application/status/${props.uuid}?new_status=approved`);
      setIsLoading(false);
      showToast(
        'success',
        'Application interview schedule updated successfully!',
      );
      props.onSubmitSuccess();
    } catch (error: any) {
      console.log(error);
      if (error.response?.status === 401 || false) {
        showToast('error', 'Session timed out, please login again!');
        navigate('/logout');
        return;
      }
      showToast(
        'error',
        `Error approving application: ${
          error.response?.data.detail[0].msg ||
          error.response?.data.detail ||
          'Unknown error'
        }`,
      );
      setIsLoading(false);
      props.onSubmitFailure();
    }
  };

  return (
    <Modal show={showModal} size="lg" onClose={props.onDismiss} popup>
      <Modal.Header className="px-6 py-4">Approve application</Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-y-8">
          <div className="grid">
            <p className="text-base font-normal text-gray-700 dark:text-white">
              Have you reviewed the application yet? Approve and enter the
              financial data needed for this application now to keep things
              moving forward.
            </p>
          </div>
          <div>
            <Label
              htmlFor="financialMode"
              value="Financial Mode"
              className="mb-2"
            />
            <Select
              id="financialMode"
              name="financialMode"
              required
              value={financialMode}
              onChange={(e) => setFinancialMode(e.target.value)}
            >
              <option value="" disabled>
                Select financial mode
              </option>
              <option value="Cash">Cash</option>
              <option value="Check">Check</option>
              <option value="Guarantee letter">Guarantee letter</option>
            </Select>
          </div>
          <div>
            <Label
              htmlFor="assistanceSource"
              value="Assistance source"
              className="mb-2"
            />
            <Select
              id="assistanceSource"
              name="assistanceSource"
              required
              value={assistanceSource}
              onChange={(e) => setAssistanceSource(e.target.value)}
            >
              <option value="" disabled>
                Select assistance source
              </option>
              <option value="Regular funds">Regular funds</option>
              <option value="PSP 2023">PSP 2023</option>
              <option value="Other">Other</option>
            </Select>
          </div>
          <div>
            <Label htmlFor="amount" value="Amount" className="mb-2" />
            <TextInput
              id="amount"
              name="amount"
              type="text"
              value={amount}
              pattern="\d+"
              onChange={handleAmountChange}
              required
            />
          </div>
          <div className="grid justify-items-center">
            <Button color="blue" type="submit" isProcessing={isLoading}>
              <UserGroupIcon className="mr-2 size-5" />
              Approve application
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default ApproveModal;
