import AuthLayout from '../layouts/AuthLayout';
import ProfilePictureCard from '../components/cards/ProfilePictureCard';
import ProfileInfoCard from '../components/cards/ProfileInfoCard';
import UpdatePasswordCard from '../components/cards/UpdatePasswordCard';
import SignatureCard from '../components/cards/SignatureCard';

export default function ProfilePage() {
  return (
    <AuthLayout>
      <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-slate-100">
        Profile Settings
      </p>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
        <ProfilePictureCard />
        <div className="grid lg:col-span-2 lg:row-span-4">
          <ProfileInfoCard />
        </div>
        <SignatureCard />
        <UpdatePasswordCard />
      </div>
    </AuthLayout>
  );
}
