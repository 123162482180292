import { useState } from 'react';
import {
  Button,
  Card,
  Datepicker,
  Label,
  Select,
  TextInput,
} from 'flowbite-react';
import { PencilIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../common/AuthContext';
import { useToast } from '../../common/ToastContext';
import { UserFormData } from '../../common/interface';
import TermsAndConditionsModal from '../modals/TermsAndConditionsModal';
import api from '../../common/api';

export default function ProfileInfoCard() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { updateUserdata, userdata } = useAuth();
  const [showTermsAndConditionModal, setShowTermsAndConditionModal] =
    useState(false);
  const [contactError, setContactError] = useState('');
  const [incomeError, setIncomeError] = useState('');
  const [birthdateError, setBirthdateError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<UserFormData>({
    profile: userdata?.profile || '1',
    first_name: userdata?.firstName || '',
    middle_name: userdata?.middleName || '',
    last_name: userdata?.lastName || '',
    barangay: userdata?.barangay || '',
    city: userdata?.city || '',
    province: userdata?.province || '',
    region: userdata?.region || '',
    birthdate: userdata?.birthdate || '',
    birthplace: userdata?.birthplace || '',
    sex: userdata?.sex || '',
    status: userdata?.status || '',
    religion: userdata?.religion || '',
    contact: userdata?.contact || '',
    education: userdata?.education || '',
    philhealth: userdata?.philhealth || '',
    occupation: userdata?.occupation || '',
    income: userdata?.income || '',
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;

    if (name === 'contact') {
      const contactRegex = /^[0-9]{10,15}$/;
      if (!contactRegex.test(value)) {
        setContactError('Please enter a valid contact number (10-15 digits).');
      } else {
        setContactError('');
      }
    }

    if (name === 'income') {
      const incomeRegex = /^\d+$/;
      if (!incomeRegex.test(value)) {
        setIncomeError('Please enter a valid income (digits only)');
      } else {
        setContactError('');
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (date: string | Date | null) => {
    if (date) {
      setFormData((prevData) => ({
        ...prevData,
        birthdate:
          typeof date === 'string' ? date : date.toISOString().split('T')[0],
      }));
      setBirthdateError('');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (formData.birthdate === '') {
      setBirthdateError('Please select valid date');
      return;
    } else {
      setBirthdateError('');
    }

    try {
      setIsLoading(true);
      await api.patch('/me', JSON.stringify(formData));
      showToast('success', 'User profile updated successfully');
      updateUserdata(formData);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      console.log(error);
      if (error.response?.status === 401 || false) {
        showToast('error', 'Session timed out, please login again!');
        navigate('logout');
        return;
      }
      showToast(
        'error',
        `Error updating profile: ${
          error.response?.data.detail[0].msg ||
          error.response?.data.detail ||
          'Unknown error'
        }`,
      );
    }
  };

  return (
    <>
      <Card>
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 gap-6 md:grid-cols-2"
        >
          <div className="grid content-end md:col-span-2">
            <p className="text-lg font-bold text-gray-800 dark:text-white lg:text-3xl">
              Profile Section
            </p>
            <p className="text-base font-normal text-gray-600 dark:text-slate-200">
              We’ll never share your details.
              <span
                className="ml-1 cursor-pointer text-indigo-500 underline hover:text-indigo-700"
                onClick={() => setShowTermsAndConditionModal(true)}
              >
                See our Privacy Policy.
              </span>
            </p>
          </div>
          <div className="md:col-span-2">
            <p className="text-base font-bold text-gray-800 dark:text-slate-200">
              Personal Information
            </p>
          </div>
          <div>
            <Label htmlFor="first_name" value="First name" className="mb-2" />
            <TextInput
              id="first_name"
              name="first_name"
              type="text"
              value={formData.first_name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <Label htmlFor="last_name" value="Last name" className="mb-2" />
            <TextInput
              id="last_name"
              name="last_name"
              type="text"
              value={formData.last_name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <Label htmlFor="middle_name" value="Middle name" className="mb-2" />
            <TextInput
              id="middle_name"
              name="middle_name"
              type="text"
              value={formData.middle_name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <Label htmlFor="sex" value="Gender" className="mb-2" />
            <Select
              id="sex"
              name="sex"
              required
              value={formData.sex}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                Select your gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Prefer not to say">Prefer not to say</option>
            </Select>
          </div>
          <div>
            <Label htmlFor="birthdate" value="Birthdate" className="mb-2" />
            <Datepicker
              id="birthdate"
              name="birthdate"
              onSelectedDateChanged={(e) => handleDateChange(e)}
              onChange={(e) => handleDateChange(e.target.value)}
              required
              color={birthdateError ? 'failure' : undefined}
            />
            {birthdateError && (
              <p className="mt-1 text-sm text-red-600">{birthdateError}</p>
            )}
          </div>
          <div>
            <Label htmlFor="birthplace" value="Birthplace" className="mb-2" />
            <TextInput
              id="birthplace"
              name="birthplace"
              type="text"
              value={formData.birthplace}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <Label htmlFor="status" value="Civil status" className="mb-2" />
            <Select
              id="status"
              name="status"
              required
              value={formData.status}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                Select civil status
              </option>
              <option value="Single">Single</option>
              <option value="Married">Married</option>
              <option value="Separated">Separated</option>
              <option value="Divorced">Divorced</option>
              <option value="Widow">Widow</option>
              <option value="Prefer not to say">Prefer not to say</option>
            </Select>
          </div>
          <div>
            <Label htmlFor="religion" value="Religion" className="mb-2" />
            <TextInput
              id="religion"
              name="religion"
              type="text"
              value={formData.religion}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="md:col-span-2">
            <p className="text-base font-bold text-gray-800 dark:text-slate-200">
              Contact Information
            </p>
          </div>
          <div>
            <Label htmlFor="contact" value="Contact Number" className="mb-2" />
            <TextInput
              id="contact"
              name="contact"
              type="text"
              value={formData.contact}
              onChange={handleInputChange}
              required
              color={contactError ? 'failure' : undefined}
            />
            {contactError && (
              <p className="mt-1 text-sm text-red-600">{contactError}</p>
            )}
          </div>
          <div>
            <Label htmlFor="barangay" value="Barangay" className="mb-2" />
            <TextInput
              id="barangay"
              name="barangay"
              type="text"
              value={formData.barangay}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <Label
              htmlFor="city"
              value="City / Municipality"
              className="mb-2"
            />
            <TextInput
              id="city"
              name="city"
              type="text"
              value={formData.city}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <Label
              htmlFor="province"
              value="Province / District"
              className="mb-2"
            />
            <TextInput
              id="province"
              name="province"
              type="text"
              value={formData.province}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <Label htmlFor="region" value="Region" className="mb-2" />
            <TextInput
              id="region"
              name="region"
              type="text"
              value={formData.region}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="md:col-span-2">
            <p className="text-base font-bold text-gray-800 dark:text-slate-200">
              Occupational Information
            </p>
          </div>
          <div>
            <Label htmlFor="education" value="Education" className="mb-2" />
            <TextInput
              id="education"
              name="education"
              type="text"
              value={formData.education}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <Label
              htmlFor="occupation"
              value="Job / Occupation"
              className="mb-2"
            />
            <TextInput
              id="occupation"
              name="occupation"
              type="text"
              value={formData.occupation}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <Label htmlFor="income" value="Monthly income" className="mb-2" />
            <TextInput
              id="income"
              name="income"
              type="text"
              value={formData.income}
              onChange={handleInputChange}
              required
              color={incomeError ? 'failure' : undefined}
            />
          </div>
          <div>
            <Label htmlFor="philhealth" value="Philhealth #" className="mb-2" />
            <TextInput
              id="philhealth"
              name="philhealth"
              type="text"
              value={formData.philhealth}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="flex items-center justify-center md:col-span-2">
            <Button color="blue" type="submit" isProcessing={isLoading}>
              <PencilIcon className="mr-2 size-5" />
              Update profile information
            </Button>
          </div>
        </form>
      </Card>
      <TermsAndConditionsModal
        show={showTermsAndConditionModal}
        onClose={() => setShowTermsAndConditionModal(false)}
      />
    </>
  );
}
