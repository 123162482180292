import { useState, useEffect } from 'react';
import {
  Badge,
  Button,
  Checkbox,
  Dropdown,
  Pagination,
  Table,
  TextInput,
} from 'flowbite-react';
import {
  HiOutlineSearch,
  HiOutlineFilter,
  HiOutlineSortAscending,
  HiOutlineSortDescending,
} from 'react-icons/hi';
import {
  TbClearAll,
  TbSortAscending2,
  TbSortDescending2,
} from 'react-icons/tb';
import UserTableAction from './user/UserTableActions';
import { getUserBadgeColor } from '../../common/utils';

export interface UserTableRow {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  profile: string;
  role: string;
  barangayRole: boolean;
  email: string;
}

interface UserTableProps {
  data: UserTableRow[];
}

export default function UserTable(props: UserTableProps) {
  const [data, setData] = useState<UserTableRow[]>(props.data);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [sortCategory, setSortCategory] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  const onPageChange = (page: number) => setCurrentPage(page);

  const handleFilter = (category: string, filterString: string) => {
    setFilterCategory(category);
    setFilter(filterString);
  };

  const handleSort = (category: string, order: string) => {
    setSortCategory(category);
    setSortOrder(order);
  };

  useEffect(() => {
    const filterUsers = () => {
      let filteredData = [...props.data];

      if (search) {
        filteredData = filteredData.filter((user) =>
          user.name.toLowerCase().includes(search.toLowerCase()),
        );
      }

      if (filter && filterCategory) {
        filteredData = filteredData.filter((user) =>
          user[filterCategory as keyof UserTableRow]
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()),
        );
      }

      if (sortCategory) {
        filteredData.sort((a, b) => {
          let aValue = a[sortCategory as keyof UserTableRow];
          let bValue = b[sortCategory as keyof UserTableRow];

          if (sortOrder === 'asc') {
            return aValue > bValue ? 1 : -1;
          } else {
            return aValue < bValue ? 1 : -1;
          }
        });
      }

      setData(filteredData);
    };

    filterUsers();
  }, [props.data, search, filter, filterCategory, sortCategory, sortOrder]);

  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const totalPages = Math.ceil(data.length / itemsPerPage);

  return (
    <>
      <div className="relative mt-2 flex w-full flex-row items-center justify-between">
        <TextInput
          id="search"
          type="text"
          icon={HiOutlineSearch}
          placeholder="Search user name"
          className="w-48 md:w-72"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="relative flex flex-row items-center justify-start space-x-2 lg:space-x-6">
          <Dropdown
            label=""
            placement="left-start"
            className="max-h-72 w-80 overflow-auto text-left"
            dismissOnClick={true}
            renderTrigger={() => (
              <Button color="gray">
                <HiOutlineFilter className="md:mr-3" size={20} />
                <p className="hidden lg:block">Filter by</p>
              </Button>
            )}
          >
            <Dropdown.Header>Filter by</Dropdown.Header>
            <Dropdown.Item
              icon={TbClearAll}
              onClick={() => handleFilter('', '')}
            >
              Clear filters
            </Dropdown.Item>
            <p className="px-4 py-2 text-sm font-semibold text-gray-800 dark:text-slate-200">
              Role
            </p>
            <Dropdown.Item onClick={() => handleFilter('role', 'user')}>
              User
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleFilter('role', 'staff')}>
              Staff
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleFilter('role', 'officer')}>
              Officer
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleFilter('role', 'admin')}>
              Admin
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleFilter('role', 'system')}>
              System
            </Dropdown.Item>
          </Dropdown>
          <Dropdown
            label=""
            placement="left-start"
            className="max-h-72 w-80 overflow-auto"
            dismissOnClick={true}
            renderTrigger={() => (
              <Button color="gray">
                {sortOrder === 'asc' ? (
                  <HiOutlineSortAscending className="md:mr-3" size={20} />
                ) : (
                  <HiOutlineSortDescending className="md:mr-3" size={20} />
                )}
                <p className="hidden lg:block">Sort by</p>
              </Button>
            )}
          >
            <Dropdown.Header>Sort by</Dropdown.Header>
            <Dropdown.Item icon={TbClearAll} onClick={() => handleSort('', '')}>
              Clear sorts
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('id', 'asc')}>
              User ID (Ascending)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('id', 'desc')}>
              User ID (Descending)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('firstName', 'asc')}>
              First Name (Ascending)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('firstName', 'desc')}>
              First Name (Descending)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('lastName', 'asc')}>
              Last Name (Ascending)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('lastName', 'desc')}>
              Last Name (Descending)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('email', 'asc')}>
              Email (Ascending)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('email', 'desc')}>
              Email (Descending)
            </Dropdown.Item>
          </Dropdown>
        </div>
      </div>
      <div className="overflow-x-auto">
        <Table>
          <Table.Head>
            <Table.HeadCell>
              <div className="flex flex-row">
                <span>User ID</span>
                {sortCategory === 'uuid' && sortOrder === 'asc' ? (
                  <TbSortAscending2 className="ml-2" size={16} />
                ) : sortCategory === 'uuid' && sortOrder === 'desc' ? (
                  <TbSortDescending2 className="ml-2" size={16} />
                ) : (
                  ''
                )}
              </div>
            </Table.HeadCell>
            <Table.HeadCell>
              <div className="flex flex-row">
                <span>Account Details</span>
                {['firstName', 'lastName', 'email'].includes(sortCategory) &&
                sortOrder === 'asc' ? (
                  <TbSortAscending2 className="ml-2" size={16} />
                ) : ['firstName', 'lastName', 'email'].includes(sortCategory) &&
                  sortOrder === 'desc' ? (
                  <TbSortDescending2 className="ml-2" size={16} />
                ) : (
                  ''
                )}
              </div>
            </Table.HeadCell>
            <Table.HeadCell>Role</Table.HeadCell>
            <Table.HeadCell>Barangay</Table.HeadCell>
            <Table.HeadCell>
              <span className="sr-only">Edit</span>
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {paginatedData.map((user, index) => (
              <Table.Row
                key={index}
                className="bg-white dark:border-gray-700 dark:bg-gray-800"
              >
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {user.id}
                </Table.Cell>
                <Table.Cell>
                  <div className="flex size-full items-center">
                    <div className="flex w-full flex-row items-center space-x-4 rounded-lg p-1.5">
                      <img
                        src={`/profiles/${user.profile}.jpg`}
                        alt="Profile"
                        className="size-10 rounded-full"
                      />
                      <div className="flex flex-col items-start justify-center">
                        <p className="text-sm font-semibold text-gray-800 dark:text-slate-100">
                          {user.name}
                        </p>
                        <p className="text-xs font-normal text-gray-600 dark:text-slate-200">
                          {user.email}
                        </p>
                      </div>
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <Badge
                    color={getUserBadgeColor(user.role)}
                    className="w-16 items-center justify-center py-1.5"
                  >
                    {user.role}
                  </Badge>
                </Table.Cell>
                <Table.Cell>
                  <Checkbox checked={user.barangayRole} disabled />
                </Table.Cell>
                <Table.Cell>
                  <UserTableAction id={user.id} role={user.role} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <div className="flex items-center justify-center">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
          showIcons
        />
      </div>
    </>
  );
}
