import { Button } from 'flowbite-react';
import { DocumentTextIcon } from '@heroicons/react/24/solid';
import AuthLayout from '../layouts/AuthLayout';

function Reports() {
  return (
    <AuthLayout>
      <div className="container mx-auto flex min-h-screen flex-col justify-start space-y-8 p-6 lg:pt-14">
        <div className="flex w-full flex-col space-y-2">
          <p className="text-xl font-extrabold text-gray-900 dark:text-white lg:text-3xl">
            Reports Generator and Tracker
          </p>
          <p className="text-base font-normal text-gray-500 dark:text-slate-100 lg:text-lg">
            Simplify the creation of comprehensive payroll and beneficiary
            reports, making it easy to generate detailed insights with just a
            few clicks. Say goodbye to manual data entry, allowing you to focus
            on what matters most while maintaining a clear overview of your
            data.
          </p>
        </div>
        <div className="flex items-center justify-end">
          <Button color="blue">
            <DocumentTextIcon className="mr-2 size-5" />
            Generate Payroll
          </Button>
        </div>
      </div>
    </AuthLayout>
  );
}

export default Reports;
