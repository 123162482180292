import { useState, useEffect } from 'react';
import { BsFileEarmarkCheck } from 'react-icons/bs';
import { IoDocumentsOutline } from 'react-icons/io5';
import { Spinner } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../common/ToastContext';
import AuthLayout from '../layouts/AuthLayout';
import StatCard from '../components/cards/StatCard';
import RecentApplicationsCard from '../components/cards/RecentApplicationsCard';
import ApplicationBreakdownCard from '../components/cards/ApplicationBreakdownCard';
import { ApplicationCardProps } from '../components/cards/ApplicationCard';
import { ApplicationApiData } from '../common/interface';
import api from '../common/api';
import {
  getSubmittedApplicationsThisMonth,
  getApprovedApplications,
  getLatestApprovedApplicationUUID,
  getReleasedApplications,
  getLatestReleasedApplicationDate,
  getMostRecentApplications,
  getApplicationsByAssistanceCategory,
} from '../common/dashboard_utils';

interface DashboardData {
  submittedApplication: number;
  submittedApplicationThisMonth: number;
  approvedApplication: number;
  latestApprovedApplication: string;
  releasedApplication: number;
  latestReleasedApplicationDate: string;
  recentApplications: ApplicationCardProps[];
  applicationCategoryBreakdown: number[];
}

const initialState: DashboardData = {
  submittedApplication: 0,
  submittedApplicationThisMonth: 0,
  approvedApplication: 0,
  latestApprovedApplication: 'No approved application',
  releasedApplication: 0,
  latestReleasedApplicationDate: 'No released application',
  recentApplications: [],
  applicationCategoryBreakdown: [0, 0, 0, 0, 0, 0, 0],
};

export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] =
    useState<DashboardData>(initialState);
  const { showToast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    async function getApplications() {
      setIsLoading(true);
      let apiResponse = { data: [] };
      try {
        apiResponse = await api.get('/applications');
        const apiData = apiResponse.data as ApplicationApiData[];
        const applicationBreakdown =
          getApplicationsByAssistanceCategory(apiData);
        setDashboardData({
          submittedApplication: apiData.length,
          submittedApplicationThisMonth:
            getSubmittedApplicationsThisMonth(apiData),
          approvedApplication: getApprovedApplications(apiData),
          latestApprovedApplication: getLatestApprovedApplicationUUID(apiData),
          releasedApplication: getReleasedApplications(apiData),
          latestReleasedApplicationDate:
            getLatestReleasedApplicationDate(apiData),
          recentApplications: getMostRecentApplications(apiData).map(
            (application, index) => ({
              uuid: application.uuid,
              category: application.assistance_category,
              status: application.status,
              purpose: application.purpose,
            }),
          ),
          applicationCategoryBreakdown: [
            applicationBreakdown.livelihood_assistance,
            applicationBreakdown.burial_assistance,
            applicationBreakdown.medical_assistance,
            applicationBreakdown.transportation_assistance,
            applicationBreakdown.philhealth_certification,
            applicationBreakdown.education_assistance,
            applicationBreakdown.others,
          ],
        });
        setIsLoading(false);
      } catch (error: any) {
        console.log(error);
        if (error.response?.status === 401 || false) {
          showToast('error', 'Session timed out, please login again!');
          navigate('/logout');
          return;
        }
        showToast(
          'error',
          `Error retrieving applications: ${
            error.response?.data.detail[0].msg ||
            error.response?.data.detail ||
            'Unknown error'
          }`,
        );
        setIsLoading(false);
      }
    }
    getApplications();
  }, [showToast, navigate]);

  return (
    <AuthLayout>
      <div
        style={{ display: isLoading ? 'flex' : 'none' }}
        className="flex size-full items-center justify-center"
      >
        <Spinner size="xl" />
      </div>
      <div
        style={{ display: isLoading ? 'none' : 'flex' }}
        className="grid h-fit w-full grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3"
      >
        <StatCard
          title="Submitted Applications"
          body={dashboardData.submittedApplication.toString()}
          subtitle={`${dashboardData.submittedApplicationThisMonth} application this month`}
          color="#9333ea"
          icon={<IoDocumentsOutline size={64} color="white" />}
        />
        <StatCard
          title="Approved Applications"
          body={dashboardData.approvedApplication.toString()}
          subtitle={`Last approved: ${dashboardData.latestApprovedApplication}`}
          color="#16a34a"
          icon={<BsFileEarmarkCheck size={64} color="white" />}
        />
        <StatCard
          title="Released Applications"
          body={dashboardData.releasedApplication.toString()}
          subtitle={`Last released: ${dashboardData.latestReleasedApplicationDate}`}
          color="#3b82f6"
          icon={<BsFileEarmarkCheck size={64} color="white" />}
        />
      </div>
      <div
        style={{ display: isLoading ? 'none' : 'flex' }}
        className="mt-6 grid grid-cols-1 gap-4 lg:grid-cols-2"
      >
        <RecentApplicationsCard
          applications={dashboardData.recentApplications}
        />
        <ApplicationBreakdownCard
          data={dashboardData.applicationCategoryBreakdown}
        />
      </div>
    </AuthLayout>
  );
}
