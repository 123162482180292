import { useState, useEffect } from 'react';
import { Modal, Button, Table } from 'flowbite-react';
import { BeneficiaryDetail } from '../tables/BeneficiaryTableFlowbite';

interface BeneficiaryDetailModalProps {
  show: boolean;
  onClose: () => void;
  beneficiaryDetail: BeneficiaryDetail;
}

export default function BeneficiaryDetailModal(
  props: BeneficiaryDetailModalProps,
) {
  const [showModal, setShowModal] = useState(props.show);

  useEffect(() => {
    setShowModal(props.show);
  }, [props.show]);

  return (
    <Modal show={showModal} size="lg" onClose={props.onClose}>
      <Modal.Header>Beneficiary Details</Modal.Header>
      <Modal.Body>
        <Table hoverable={true}>
          <tbody>
            <tr>
              <td className="pr-4 text-right font-semibold">First Name:</td>
              <td>{props.beneficiaryDetail.firstName}</td>
            </tr>
            <tr>
              <td className="pr-4 text-right font-semibold">Middle Name:</td>
              <td>{props.beneficiaryDetail.middleName}</td>
            </tr>
            <tr>
              <td className="pr-4 text-right font-semibold">Last Name:</td>
              <td>{props.beneficiaryDetail.lastName}</td>
            </tr>
            <tr>
              <td className="pr-4 text-right font-semibold">Barangay:</td>
              <td>{props.beneficiaryDetail.barangay}</td>
            </tr>
            <tr>
              <td className="pr-4 text-right font-semibold">City:</td>
              <td>{props.beneficiaryDetail.city}</td>
            </tr>
            <tr>
              <td className="pr-4 text-right font-semibold">Province:</td>
              <td>{props.beneficiaryDetail.province}</td>
            </tr>
            <tr>
              <td className="pr-4 text-right font-semibold">Region:</td>
              <td>{props.beneficiaryDetail.region}</td>
            </tr>
            <tr>
              <td className="pr-4 text-right font-semibold">Birthdate:</td>
              <td>{props.beneficiaryDetail.birthdate}</td>
            </tr>
            <tr>
              <td className="pr-4 text-right font-semibold">Sex:</td>
              <td>{props.beneficiaryDetail.sex}</td>
            </tr>
            <tr>
              <td className="pr-4 text-right font-semibold">Status:</td>
              <td>{props.beneficiaryDetail.status}</td>
            </tr>
            <tr>
              <td className="pr-4 text-right font-semibold">Contact:</td>
              <td>{props.beneficiaryDetail.contact}</td>
            </tr>
            <tr>
              <td className="pr-4 text-right font-semibold">Occupation:</td>
              <td>{props.beneficiaryDetail.occupation}</td>
            </tr>
            <tr>
              <td className="pr-4 text-right font-semibold">Income:</td>
              <td>{props.beneficiaryDetail.income}</td>
            </tr>
          </tbody>
        </Table>
        <div className="flex items-center justify-center">
          <Button onClick={props.onClose} className="mt-4 px-6" color="blue">
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
