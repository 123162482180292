import { useState } from 'react';
import { Card, Badge } from 'flowbite-react';
import { useAuth } from '../../common/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../common/ToastContext';
import SelectProfileModal from '../modals/SelectProfileModal';
import { getUserBadgeColor, titleCase } from '../../common/utils';
import api from '../../common/api';

export default function ProfilePictureCard() {
  const navigate = useNavigate();
  const { userdata, updateUserdata } = useAuth();
  const { showToast } = useToast();
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(
    userdata?.profile ?? '1',
  );

  const handleSave = async (profile: string) => {
    try {
      await api.patch('/me', JSON.stringify({ profile: profile }));
      updateUserdata({ ...userdata, profile: profile });
      setSelectedProfile(profile);
      showToast('success', 'User profile picture updated successfully');
      setShowProfileModal(false);
    } catch (error: any) {
      console.log(error);
      if (error.response?.status === 401 || false) {
        showToast('error', 'Session timed out, please login again!');
        navigate('logout');
        return;
      }
      showToast(
        'error',
        `Error updating profile picture: ${
          error.response?.data.detail[0].msg ||
          error.response?.data.detail ||
          'Unknown error'
        }`,
      );
      setShowProfileModal(false);
    }
  };

  return (
    <>
      <Card>
        <div className="grid justify-items-center">
          <div
            className="group relative cursor-pointer"
            onClick={() => setShowProfileModal(true)}
          >
            <img
              src={`/profiles/${selectedProfile}.jpg`}
              alt="Profile"
              className="group size-32 rounded-full group-hover:brightness-75"
            />
            <p className="absolute left-2.5 top-16 hidden text-center text-sm font-medium text-slate-100 group-hover:block">
              Choose profile picture
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center space-x-2">
          <p className="text-center text-lg font-semibold text-gray-900 dark:text-slate-200">
            {`${userdata?.firstName ?? 'Default'} ${
              userdata?.lastName ?? 'User'
            }`}
          </p>
          <Badge
            color={getUserBadgeColor('user')}
            className="w-16 items-center justify-center py-1.5"
          >
            {titleCase(userdata?.role ?? 'default')}
          </Badge>
        </div>
      </Card>
      <SelectProfileModal
        show={showProfileModal}
        onDismiss={() => setShowProfileModal(false)}
        onProfileSelect={handleSave}
      />
    </>
  );
}
