import { useState, useEffect } from 'react';
import { Button, Card, Spinner } from 'flowbite-react';
import { UserPlusIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../common/ToastContext';
import AuthLayout from '../layouts/AuthLayout';
import UserCreateModal from '../components/modals/UserCreateModal';
import { UserTableRow } from '../components/tables/UserTableFlowbite';
import { UserApiData } from '../common/interface';
import UserTable from '../components/tables/UserTableFlowbite';
import api from '../common/api';

export default function Users() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [showUserCreateModal, setShowUserCreateModal] = useState(false);
  const [rows, setRows] = useState<UserTableRow[]>([]);

  useEffect(() => {
    async function getUsers() {
      setIsLoading(true);
      let apiResponse = { data: [] };
      try {
        apiResponse = await api.get('/users');
        console.log(apiResponse.data);
        const apiData = apiResponse.data as UserApiData[];
        const users: UserTableRow[] = apiData.map((user, index) => ({
          id: user.id.toString(),
          email: user.email,
          firstName: user.first_name,
          lastName: user.last_name,
          name: `${user.first_name} ${user.last_name}`,
          role: user.role,
          barangayRole: user.barangay_role,
          profile: user.profile,
        }));
        setRows(users);
        setIsLoading(false);
      } catch (error: any) {
        console.log(error);
        if (error.response?.status === 401 || false) {
          showToast('error', 'Session timed out, please login again!');
          navigate('/logout');
          return;
        }
        showToast(
          'error',
          `Error retrieving users: ${
            error.response?.data.detail[0].msg ||
            error.response?.data.detail ||
            'Unknown error'
          }`,
        );
        setIsLoading(false);
      }
    }
    getUsers();
  }, [showToast, navigate]);

  return (
    <AuthLayout>
      <div
        style={{ display: isLoading ? 'flex' : 'none' }}
        className="flex size-full items-center justify-center"
      >
        <Spinner size="xl" />
      </div>
      <Card style={{ display: isLoading ? 'none' : 'flex' }} className="w-full">
        <p className="text-lg font-semibold text-gray-900 dark:text-slate-100">
          Users
        </p>
        <div className="flex w-full flex-row items-center justify-end">
          <Button color="blue" onClick={() => setShowUserCreateModal(true)}>
            <UserPlusIcon className="mr-2 size-5" />
            Create new user
          </Button>
        </div>
        <UserTable data={rows} />
      </Card>
      <UserCreateModal
        show={showUserCreateModal}
        onDismiss={() => setShowUserCreateModal(false)}
        onFormSubmitSuccess={() => setShowUserCreateModal(false)}
        onFormSubmitFailure={() => setShowUserCreateModal(false)}
      />
    </AuthLayout>
  );
}
