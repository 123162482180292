import { Navbar, DarkThemeToggle } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '../../common/ThemeContext';

export default function NonAuthNavbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { toggleTheme } = useTheme();

  const navlinks = [
    { link: '/', label: 'Home' },
    { link: '/login', label: 'Login' },
    { link: '/register', label: 'Register' },
  ];

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  return (
    <Navbar fluid rounded className="border-b-2 border-gray-100">
      <Navbar.Brand onClick={() => navigate('/')}>
        <img src="/logo.png" className="mr-3 h-6 sm:h-9" alt="MSWDash Logo" />
        <span className="self-center whitespace-nowrap text-xl font-bold dark:text-white">
          MSWDash
        </span>
      </Navbar.Brand>
      <div className="flex space-x-1 md:order-2">
        <DarkThemeToggle onClick={() => toggleTheme()} className="mr-5" />

        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        {navlinks.map((navlink, index) => (
          <p
            key={index}
            className={
              isActive(navlink.link)
                ? 'bg-blue-700 text-white dark:text-white md:bg-transparent md:text-blue-700'
                : 'border-b border-gray-100 text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:bg-transparent md:dark:hover:text-white'
            }
          >
            <Link to={navlink.link}>{navlink.label}</Link>
          </p>
        ))}
      </Navbar.Collapse>
    </Navbar>
  );
}
