import { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import { Card, Spinner } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../common/ToastContext';
import AuthLayout from '../layouts/AuthLayout';
import ScheduleCalendar, {
  CalendarEvent,
} from '../components/calendars/ScheduleCalendar';
import { ScheduleApiData } from '../common/interface';
import { stringToColor, titleCase } from '../common/utils';
import api from '../common/api';

export default function Calendar() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState<CalendarEvent[]>([]);

  const getEventTitle = (data: ScheduleApiData): string => {
    const dateString = format(parseISO(data.timestamp), 'MMMM dd, yyyy');
    return `${dateString} (${titleCase(data.agenda)})`;
  };

  const getEventContent = (data: ScheduleApiData): string => {
    const dateString = format(parseISO(data.timestamp), 'MMMM dd, yyyy');
    switch (data.agenda) {
      case 'interview':
        return `Application interview on ${dateString} at ${
          data.location ?? 'Unknown location'
        } for Application <${data.uuid}>`;
      case 'release':
        return `Application <${data.uuid}> is scheduled to be released on ${dateString}`;
      default:
        return `Application <${data.uuid}> - (${data.agenda})`;
    }
  };

  useEffect(() => {
    async function getApplications() {
      setIsLoading(true);
      let apiResponse = { data: [] };
      try {
        apiResponse = await api.get('/schedules');
        const apiData = apiResponse.data as ScheduleApiData[];
        const schedules: CalendarEvent[] = apiData.map((schedule, index) => ({
          date: parseISO(schedule.timestamp),
          agenda: titleCase(schedule.agenda),
          title: getEventTitle(schedule),
          content: getEventContent(schedule),
          color: stringToColor(`${schedule.agenda}-${schedule.uuid}`),
        }));
        setEvents(schedules);
        setIsLoading(false);
      } catch (error: any) {
        console.log(error);
        if (error.response?.status === 401 || false) {
          showToast('error', 'Session timed out, please login again!');
          navigate('/logout');
          return;
        }
        showToast(
          'error',
          `Error retrieving schedules: ${
            error.response?.data.detail[0].msg ||
            error.response?.data.detail ||
            'Unknown error'
          }`,
        );
        setIsLoading(false);
      }
    }
    getApplications();
  }, [showToast, navigate]);

  return (
    <AuthLayout>
      <div
        style={{ display: isLoading ? 'flex' : 'none' }}
        className="flex size-full items-center justify-center"
      >
        <Spinner size="xl" />
      </div>
      <Card style={{ display: isLoading ? 'none' : 'flex' }} className="w-full">
        <p className="text-lg font-semibold text-gray-900 dark:text-slate-100">
          Schedule Tracker
        </p>
        <ScheduleCalendar events={events} />
      </Card>
    </AuthLayout>
  );
}
