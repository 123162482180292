import { Card } from 'flowbite-react';
import ApplicationCard from './ApplicationCard';
import { ApplicationCardProps } from './ApplicationCard';

interface RecentApplicationsCardProps {
  applications: ApplicationCardProps[];
}

export default function RecentApplicationsCard(
  props: RecentApplicationsCardProps,
) {
  return (
    <Card className="h-fit w-full">
      <div className="flex flex-col space-y-2.5">
        <p className="text-lg font-semibold text-gray-900 dark:text-slate-100">
          Recent Applications
        </p>
        <div className="flex max-h-96 flex-col space-y-2.5 overflow-auto  md:h-72">
          {props.applications.map((application, index) => (
            <ApplicationCard
              key={index}
              uuid={application.uuid}
              category={application.category}
              status={application.status}
              purpose={application.purpose}
            />
          ))}
        </div>
      </div>
    </Card>
  );
}
