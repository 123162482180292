import { useState, useEffect } from 'react';
import { Button, Dropdown, Pagination, Table, TextInput } from 'flowbite-react';
import {
  HiOutlineSearch,
  HiOutlineFilter,
  HiOutlineSortAscending,
  HiOutlineSortDescending,
} from 'react-icons/hi';
import {
  TbClearAll,
  TbSortAscending2,
  TbSortDescending2,
} from 'react-icons/tb';
import BeneficiaryTableActions from './beneficiary/BeneficiaryTableActions';

export interface BeneficiaryDetail {
  firstName: string;
  middleName: string;
  lastName: string;
  name: string;
  barangay: string;
  city: string;
  province: string;
  region: string;
  birthdate: string;
  birthplace: string;
  sex: string;
  status: string;
  contact: string;
  occupation: string;
  income: number;
}

interface BeneficiaryTableProps {
  data: BeneficiaryDetail[];
}

export default function BeneficiaryTable(props: BeneficiaryTableProps) {
  const [data, setData] = useState<BeneficiaryDetail[]>(props.data);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [sortCategory, setSortCategory] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  const onPageChange = (page: number) => setCurrentPage(page);

  const handleFilter = (category: string, filterString: string) => {
    setFilterCategory(category);
    setFilter(filterString);
  };

  const handleSort = (category: string, order: string) => {
    setSortCategory(category);
    setSortOrder(order);
  };

  const parseCustomDate = (dateString: string) => {
    return new Date(Date.parse(dateString));
  };

  useEffect(() => {
    const filterUsers = () => {
      let filteredData = [...props.data];

      if (search) {
        filteredData = filteredData.filter((user) =>
          user.name.toLowerCase().includes(search.toLowerCase()),
        );
      }

      if (filter && filterCategory) {
        filteredData = filteredData.filter((user) =>
          user[filterCategory as keyof BeneficiaryDetail]
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()),
        );
      }

      if (sortCategory) {
        filteredData.sort((a, b) => {
          let aValue = a[sortCategory as keyof BeneficiaryDetail];
          let bValue = b[sortCategory as keyof BeneficiaryDetail];

          if (sortOrder === 'asc') {
            return aValue > bValue ? 1 : -1;
          } else {
            return aValue < bValue ? 1 : -1;
          }
        });
      }

      if (sortCategory) {
        filteredData.sort((a, b) => {
          let aValue = a[sortCategory as keyof BeneficiaryDetail];
          let bValue = b[sortCategory as keyof BeneficiaryDetail];

          if (sortCategory === 'birthdate') {
            aValue = parseCustomDate(a.birthdate).getTime();
            bValue = parseCustomDate(b.birthdate).getTime();
          }

          if (sortOrder === 'asc') {
            return aValue > bValue ? 1 : -1;
          } else {
            return aValue < bValue ? 1 : -1;
          }
        });
      }

      setData(filteredData);
    };

    filterUsers();
  }, [props.data, search, filter, filterCategory, sortCategory, sortOrder]);

  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const totalPages = Math.ceil(data.length / itemsPerPage);

  return (
    <>
      <div className="relative mt-2 flex w-full flex-row items-center justify-between">
        <TextInput
          id="search"
          type="text"
          icon={HiOutlineSearch}
          placeholder="Search user name"
          className="w-48 md:w-72"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="relative flex flex-row items-center justify-start space-x-2 lg:space-x-6">
          <Dropdown
            label=""
            placement="left-start"
            className="max-h-72 w-80 overflow-auto text-left"
            dismissOnClick={true}
            renderTrigger={() => (
              <Button color="gray">
                <HiOutlineFilter className="md:mr-3" size={20} />
                <p className="hidden lg:block">Filter by</p>
              </Button>
            )}
          >
            <Dropdown.Header>Filter by</Dropdown.Header>
            <Dropdown.Item
              icon={TbClearAll}
              onClick={() => handleFilter('', '')}
            >
              Clear filters
            </Dropdown.Item>
            <p className="px-4 py-2 text-sm font-semibold text-gray-800 dark:text-slate-200">
              Sex
            </p>
            <Dropdown.Item onClick={() => handleFilter('sex', 'Male')}>
              Male
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleFilter('sex', 'Female')}>
              Female
            </Dropdown.Item>
            <p className="px-4 py-2 text-sm font-semibold text-gray-800 dark:text-slate-200">
              Status
            </p>
            <Dropdown.Item onClick={() => handleFilter('status', 'Single')}>
              Single
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleFilter('status', 'Married')}>
              Married
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleFilter('status', 'Separated')}>
              Separated
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleFilter('status', 'Divorced')}>
              Divorced
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleFilter('status', 'Widow')}>
              Widow
            </Dropdown.Item>
          </Dropdown>
          <Dropdown
            label=""
            placement="left-start"
            className="max-h-72 w-80 overflow-auto"
            dismissOnClick={true}
            renderTrigger={() => (
              <Button color="gray">
                {sortOrder === 'asc' ? (
                  <HiOutlineSortAscending className="md:mr-3" size={20} />
                ) : (
                  <HiOutlineSortDescending className="md:mr-3" size={20} />
                )}
                <p className="hidden lg:block">Sort by</p>
              </Button>
            )}
          >
            <Dropdown.Header>Sort by</Dropdown.Header>
            <Dropdown.Item icon={TbClearAll} onClick={() => handleSort('', '')}>
              Clear sorts
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('firstName', 'asc')}>
              First Name (Ascending)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('firstName', 'desc')}>
              First Name (Descending)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('lastName', 'asc')}>
              Last Name (Ascending)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('lastName', 'desc')}>
              Last Name (Descending)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('barangay', 'asc')}>
              Barangay (Ascending)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('barangay', 'desc')}>
              Barangay (Descending)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('city', 'asc')}>
              City (Ascending)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('city', 'desc')}>
              City (Descending)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('birthdate', 'asc')}>
              Birthdate (Ascending)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('birthdate', 'desc')}>
              Birthdate (Descending)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('income', 'asc')}>
              Income (Ascending)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('income', 'desc')}>
              Income (Descending)
            </Dropdown.Item>
          </Dropdown>
        </div>
      </div>
      <div className="overflow-x-auto">
        <Table>
          <Table.Head>
            <Table.HeadCell>
              <div className="flex flex-row">
                <span>Name</span>
                {['firstName', 'lastName'].includes(sortCategory) &&
                sortOrder === 'asc' ? (
                  <TbSortAscending2 className="ml-2" size={16} />
                ) : ['firstName', 'lastName'].includes(sortCategory) &&
                  sortOrder === 'desc' ? (
                  <TbSortDescending2 className="ml-2" size={16} />
                ) : (
                  ''
                )}
              </div>
            </Table.HeadCell>
            <Table.HeadCell>
              <div className="flex flex-row">
                <span>Barangay</span>
                {sortCategory === 'barangay' && sortOrder === 'asc' ? (
                  <TbSortAscending2 className="ml-2" size={16} />
                ) : sortCategory === 'barangay' && sortOrder === 'desc' ? (
                  <TbSortDescending2 className="ml-2" size={16} />
                ) : (
                  ''
                )}
              </div>
            </Table.HeadCell>
            <Table.HeadCell>
              <div className="flex flex-row">
                <span>City</span>
                {sortCategory === 'city' && sortOrder === 'asc' ? (
                  <TbSortAscending2 className="ml-2" size={16} />
                ) : sortCategory === 'city' && sortOrder === 'desc' ? (
                  <TbSortDescending2 className="ml-2" size={16} />
                ) : (
                  ''
                )}
              </div>
            </Table.HeadCell>
            <Table.HeadCell>
              <div className="flex flex-row">
                <span>Birthdate</span>
                {sortCategory === 'birthdate' && sortOrder === 'asc' ? (
                  <TbSortAscending2 className="ml-2" size={16} />
                ) : sortCategory === 'birthdate' && sortOrder === 'desc' ? (
                  <TbSortDescending2 className="ml-2" size={16} />
                ) : (
                  ''
                )}
              </div>
            </Table.HeadCell>
            <Table.HeadCell>Sex</Table.HeadCell>
            <Table.HeadCell>Status</Table.HeadCell>
            <Table.HeadCell>
              <div className="flex flex-row">
                <span>Income</span>
                {sortCategory === 'income' && sortOrder === 'asc' ? (
                  <TbSortAscending2 className="ml-2" size={16} />
                ) : sortCategory === 'income' && sortOrder === 'desc' ? (
                  <TbSortDescending2 className="ml-2" size={16} />
                ) : (
                  ''
                )}
              </div>
            </Table.HeadCell>
            <Table.HeadCell>
              <span className="sr-only">Edit</span>
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {paginatedData.map((beneficiary, index) => (
              <Table.Row
                key={index}
                className="bg-white dark:border-gray-700 dark:bg-gray-800"
              >
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {beneficiary.name}
                </Table.Cell>
                <Table.Cell>{beneficiary.barangay}</Table.Cell>
                <Table.Cell>{beneficiary.city}</Table.Cell>
                <Table.Cell>{beneficiary.birthdate}</Table.Cell>
                <Table.Cell>{beneficiary.sex}</Table.Cell>
                <Table.Cell>{beneficiary.status}</Table.Cell>
                <Table.Cell>{beneficiary.income}</Table.Cell>
                <Table.Cell>
                  <BeneficiaryTableActions detail={beneficiary} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <div className="flex items-center justify-center">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
          showIcons
        />
      </div>
    </>
  );
}
