import { ReactNode, useState } from 'react';
import AuthNavbar from '../components/navigations/AuthNavbar';
import AuthSidebar from '../components/navigations/AuthSidebar';

interface AuthLayoutProps {
  children?: ReactNode;
}

export default function AuthLayout({ children }: AuthLayoutProps) {
  const [showSidebar, setShowSidebar] = useState<boolean>(false);

  return (
    <main className="flex h-screen flex-col dark:bg-gray-800">
      <AuthNavbar onToggleClick={() => setShowSidebar(!showSidebar)} />
      <div className="flex flex-row" style={{ height: 'calc(100vh - 65px)' }}>
        <AuthSidebar hidden={showSidebar} />
        <div className="flex size-full flex-col overflow-auto bg-slate-50 px-6 pt-6 dark:bg-gray-700">
          {children}
        </div>
      </div>
    </main>
  );
}
