import { useState, useEffect } from 'react';
import { FileInput, Label, Select, Button } from 'flowbite-react';
import { BarangayApplicationFormData } from '../AddBarangayApplicationModal';
import { snakeToUpperCase, toSnakeCase } from '../../../common/utils';

interface ApplicationStepContentProps {
  applicationFormData: BarangayApplicationFormData;
  handleFileInputChange: (
    key: string,
    event: React.ChangeEvent<HTMLInputElement>,
    formData: BarangayApplicationFormData,
  ) => void;
  handleFileDelete: (key: string) => void;
}

interface Requirement {
  name: string;
  required: boolean;
}

const allRequirements = [
  'barangay_endorsement_letter',
  'beneficiary_list',
  'beneficiary_valid_ids',
  'disaster_assessment_report',
  'official_receipts',
  'incident_report',
  'photos_of_evidence',
  'additional_proof',
];

const requirements = {
  none: [
    { name: 'certificate_of_indigence', required: true },
    { name: 'valid_id', required: true },
    { name: 'funeral_contract', required: true },
    { name: 'death_certificate', required: true },
  ],
};

export default function AddBarangayApplicationStep2({
  applicationFormData,
  handleFileInputChange,
  handleFileDelete,
}: ApplicationStepContentProps) {
  const [selectedRequirements, setSelectedRequirements] = useState<
    Requirement[]
  >([]);
  const [additionalRequirements, setAdditionalRequirements] = useState<{
    [key: string]: File | null;
  }>({});

  useEffect(() => {
    const initialRequirements =
      requirements[
        toSnakeCase(
          applicationFormData.assistanceCategory,
        ) as keyof typeof requirements
      ] || [];
    setSelectedRequirements(initialRequirements);
    const initialAdditionalRequirements = initialRequirements.reduce(
      (acc, { name }) => {
        acc[name] = null;
        return acc;
      },
      {} as { [key: string]: File | null },
    );
    setAdditionalRequirements(initialAdditionalRequirements);
  }, [applicationFormData.assistanceCategory]);

  // Handle dropdown selection
  const handleRequirementChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (value && !selectedRequirements.some((req) => req.name === value)) {
      const selectedReq = allRequirements.find((req) => req === value);
      if (selectedReq) {
        setSelectedRequirements((prev) => [
          ...prev,
          { name: selectedReq, required: false },
        ]);
        setAdditionalRequirements((prev) => ({
          ...prev,
          [selectedReq]: null,
        }));
      }
    }
  };

  // Remove selected requirement
  const handleRemoveRequirement = (requirement: string) => () => {
    handleFileDelete(requirement);
    setSelectedRequirements((prev) =>
      prev.filter((r) => r.name !== requirement),
    );
    setAdditionalRequirements((prev) => {
      const { [requirement]: _, ...rest } = prev;
      return rest;
    });
  };

  // Filter out already selected requirements from dropdown
  const availableRequirements = allRequirements.filter(
    (req) =>
      !selectedRequirements.some((selectedReq) => selectedReq.name === req),
  );

  return (
    <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
      <div className="grid md:col-span-2">
        <p className="text-base font-normal text-gray-500 dark:text-slate-300">
          Upload application requirements. Requirements you upload are sent to
          staffs for careful review and considerations.
        </p>
      </div>

      {/* Dropdown to select additional requirements */}
      <div className="grid md:col-span-2 md:w-1/2">
        <Label
          htmlFor="additionalRequirements"
          value="Additional Requirements"
          className="mb-2"
        />
        <Select
          id="additionalRequirements"
          onChange={handleRequirementChange}
          value=""
        >
          <option value="" disabled>
            Select additional requirement
          </option>
          {availableRequirements.map((req) => (
            <option key={req} value={req}>
              {snakeToUpperCase(req)}
            </option>
          ))}
        </Select>
      </div>

      {/* Display file inputs for selected requirements */}
      {selectedRequirements.map((req) => (
        <div key={req.name} className="mb-4">
          <Label
            htmlFor={req.name}
            value={snakeToUpperCase(req.name)}
            className="mb-2"
          />
          <FileInput
            id={req.name}
            accept="application/pdf"
            onChange={(e) =>
              handleFileInputChange(req.name, e, applicationFormData)
            }
            required
            color={additionalRequirements[req.name] ? undefined : 'failure'}
          />
          {!req.required && (
            <Button
              onClick={handleRemoveRequirement(req.name)}
              color="failure"
              className="mt-2"
            >
              Remove
            </Button>
          )}
        </div>
      ))}
    </div>
  );
}
