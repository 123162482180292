import { useState, useEffect } from 'react';
import ScheduleChip from '../chips/ScheduleChip';

const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export interface CalendarEvent {
  date: Date;
  agenda: string;
  title: string;
  content: string;
  color: string;
}

interface ScheduleCalendarProps {
  events: CalendarEvent[];
}

export default function ScheduleCalendar(props: ScheduleCalendarProps) {
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0);
  const [noOfDays, setNoOfDays] = useState<number[]>([]);
  const [blankDays, setBlankDays] = useState<number[]>([]);

  useEffect(() => {
    let today = new Date();
    setMonth(today.getMonth());
    setYear(today.getFullYear());
  }, []);

  useEffect(() => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const dayOfWeek = new Date(year, month).getDay();

    let blankdaysArray = Array.from({ length: dayOfWeek }, (_, i) => i + 1);
    let daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);

    setBlankDays(blankdaysArray);
    setNoOfDays(daysArray);
  }, [month, year]);

  const isToday = (date: number) => {
    const today = new Date();
    const d = new Date(year, month, date);
    return today.toDateString() === d.toDateString();
  };

  const handlePreviousMonth = () => {
    if (month === 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const handleNextMonth = () => {
    if (month === 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  return (
    <div className="container mx-auto px-4 py-2 ">
      <div className="overflow-hidden rounded-lg border border-gray-200 bg-white shadow-lg">
        <div className="flex items-center justify-between px-6 py-2">
          <div>
            <span className="text-lg font-bold text-gray-800">
              {MONTH_NAMES[month]}
            </span>
            <span className="ml-1 text-lg font-normal text-gray-600">
              {year}
            </span>
          </div>
          <div className="rounded-lg border px-1" style={{ paddingTop: '2px' }}>
            <button
              type="button"
              className={`inline-flex cursor-pointer items-center rounded-lg p-1 leading-none transition duration-100 ease-in-out hover:bg-gray-200 ${
                month === 0 ? 'cursor-not-allowed opacity-25' : ''
              }`}
              onClick={handlePreviousMonth}
              disabled={month === 0}
            >
              <svg
                className="inline-flex size-6 leading-none text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
            <div className="inline-flex h-6 border-r"></div>
            <button
              type="button"
              className={`inline-flex cursor-pointer items-center rounded-lg p-1 leading-none transition duration-100 ease-in-out hover:bg-gray-200 ${
                month === 11 ? 'cursor-not-allowed opacity-25' : ''
              }`}
              onClick={handleNextMonth}
              disabled={month === 11}
            >
              <svg
                className="inline-flex size-6 leading-none text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="-mx-1 -mb-1">
          <div className="flex flex-wrap">
            {DAYS.map((day, index) => (
              <div key={index} style={{ width: '14.26%' }} className="p-2">
                <div className="text-center text-sm font-bold uppercase tracking-wide text-gray-600">
                  {day}
                </div>
              </div>
            ))}
          </div>

          <div className="flex flex-wrap border-l border-t">
            {blankDays.map((_, index) => (
              <div
                key={index}
                style={{ width: '14.28%', height: '120px' }}
                className="border-b border-r px-4 pt-2 text-center"
              ></div>
            ))}
            {noOfDays.map((date, index) => (
              <div
                key={index}
                style={{ width: '14.28%', height: '120px' }}
                className="relative border-b border-r px-4 pt-2"
              >
                <div
                  className={`inline-flex size-6 cursor-pointer items-center justify-center rounded-full text-center leading-none transition duration-100 ease-in-out ${
                    isToday(date)
                      ? 'bg-blue-500 text-white'
                      : 'text-gray-700 hover:bg-blue-200'
                  }`}
                >
                  {date}
                </div>
                <div style={{ height: '80px' }} className="mt-1">
                  {props.events
                    .filter(
                      (e) =>
                        new Date(e.date).toDateString() ===
                        new Date(year, month, date).toDateString(),
                    )
                    .map((event, index) => (
                      <ScheduleChip
                        key={index}
                        agenda={event.agenda}
                        color={event.color}
                        title={event.title}
                        content={event.content}
                      />
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
