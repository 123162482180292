import { Card } from 'flowbite-react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

const labels = [
  'Livelihood assistance',
  'Burial assistance',
  'Medical assistance',
  'Transportation assistance',
  'Philhealth certification',
  'Education assistance',
  'Others',
];

const colors = [
  '#FF5733',
  '#FFBD33',
  '#75FF33',
  '#33FFBD',
  '#337BFF',
  '#8D33FF',
  '#FF33A6',
];

interface ApplicationBreakdownCardProps {
  data: number[];
}

export default function ApplicationBreakdownCard(
  props: ApplicationBreakdownCardProps,
) {
  const chartOptions: ApexOptions = {
    chart: {
      type: 'donut',
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
    },
    dataLabels: {
      enabled: false,
    },
    labels: labels,
    colors: colors,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  };

  return (
    <Card className="h-fit w-full">
      <div className="flex flex-col space-y-2.5">
        <p className="text-lg font-semibold text-gray-900 dark:text-slate-100">
          Applications by Type
        </p>
        <div className="flex min-h-72 w-full items-center justify-center">
          <Chart
            options={chartOptions}
            series={props.data}
            type="donut"
            width={350}
          />
        </div>
      </div>
    </Card>
  );
}
