import { Badge, Card } from 'flowbite-react';
import { IoDocumentAttachSharp } from 'react-icons/io5';
import { getStatusBadgeColor } from '../../common/utils';

export interface ApplicationCardProps {
  uuid: string;
  category: string;
  status: string;
  purpose: string;
}

export default function ApplicationCard(props: ApplicationCardProps) {
  return (
    <Card className="w-full">
      <div className="flex flex-row space-x-4">
        <IoDocumentAttachSharp size={48} color="#22c55e" />
        <div className="flex w-full flex-col">
          <div className="flex flex-row justify-between">
            <p className="text-base font-semibold text-gray-800 dark:text-slate-200">
              {props.uuid}
            </p>
            <Badge color={getStatusBadgeColor(props.status)}>
              {props.status}
            </Badge>
          </div>
          <p className="text-sm font-medium text-gray-700 dark:text-slate-300">
            {props.category}
          </p>
          <p className="text-sm font-normal text-gray-600 dark:text-slate-400">
            {props.purpose}
          </p>
        </div>
      </div>
    </Card>
  );
}
