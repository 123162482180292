import {
  createContext,
  ReactNode,
  useEffect,
  useState,
  useContext,
} from 'react';
import { Flowbite, CustomFlowbiteTheme } from 'flowbite-react';

interface ThemeContextType {
  theme: string;
  toggleTheme: () => void;
}

const customTheme: CustomFlowbiteTheme = {
  navbar: {
    link: {
      active: {
        on: 'bg-blue-700 text-white dark:text-white md:bg-transparent md:text-blue-700',
        off: 'border-b border-gray-100 text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:bg-transparent md:dark:hover:text-white',
      },
    },
  },
  textInput: {
    field: {
      input: {
        colors: {
          gray: 'border-gray-300 bg-gray-50 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500',
        },
      },
    },
  },
  sidebar: {
    root: {
      inner:
        'h-full overflow-y-auto overflow-x-hidden rounded bg-white border-r border-gray-200 px-3 py-4 dark:bg-gray-800',
    },
    item: {
      base: 'flex items-center justify-center rounded-lg p-2 text-base font-normal text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 cursor-pointer',
    },
  },
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const AppThemeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [theme, setTheme] = useState('');

  useEffect(() => {
    let savedTheme = localStorage.getItem('flowbite-theme-mode') || 'light';
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      savedTheme = 'dark';
    }
    setTheme(savedTheme);
  }, []);

  const toggleTheme = () => {
    let newTheme = 'dark';
    if (document.documentElement.classList.contains('dark')) {
      newTheme = 'light';
      document.documentElement.classList.remove('dark');
    } else {
      document.documentElement.classList.add('dark');
    }
    localStorage.setItem('flowbite-theme-mode', newTheme);
    setTheme(newTheme);
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme,
      }}
    >
      <Flowbite theme={{ theme: customTheme }}>{children}</Flowbite>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within an AppThemeProvider');
  }
  return context;
};
