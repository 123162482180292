import { Badge, Button, Sidebar } from 'flowbite-react';
import { HiOutlineUsers } from 'react-icons/hi';
import {
  HiOutlineSquare3Stack3D,
  HiOutlineClipboardDocument,
  HiOutlineCalendarDays,
  HiOutlineCog6Tooth,
  HiOutlineUserCircle,
} from 'react-icons/hi2';
import { CgLogOut } from 'react-icons/cg';
import {
  TbBuildingCommunity,
  TbFileCertificate,
  TbUserShare,
} from 'react-icons/tb';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../common/AuthContext';

interface AuthSidebarProps {
  hidden?: boolean;
}

export default function AuthSidebar(props: AuthSidebarProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { userdata } = useAuth();

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  return (
    <Sidebar
      className={`sidebar-sm  fixed left-0 z-40 h-full w-80 transition-transform duration-300 ease-in-out md:relative ${
        props.hidden ? '-translate-x-full' : 'translate-x-0'
      }`}
    >
      <div className="flex h-full flex-col justify-between">
        <Sidebar.Items>
          <div className="flex rounded-lg bg-slate-200 p-1.5 dark:bg-gray-700">
            <div className="flex w-full flex-row items-center space-x-2 overflow-hidden">
              <img
                src={`/profiles/${userdata?.profile ?? '1'}.jpg`}
                alt="Profile"
                className="size-10 rounded-full"
              />
              <div className="flex flex-col items-start justify-center">
                <p className="truncate text-sm font-semibold text-gray-800 dark:text-slate-100">
                  {`${userdata?.firstName ?? 'Default'} ${
                    userdata?.lastName ?? 'User'
                  }`}
                </p>
                <p className="truncate text-xs font-normal text-gray-600 dark:text-slate-200">
                  {userdata?.email ?? 'defaultuser@msdwash.online'}
                </p>
              </div>
            </div>
          </div>
          <Sidebar.ItemGroup>
            <Sidebar.Item
              icon={HiOutlineSquare3Stack3D}
              active={isActive('/dashboard')}
              onClick={() => navigate('/dashboard')}
            >
              Dashboard
            </Sidebar.Item>
            <Sidebar.Collapse
              icon={HiOutlineClipboardDocument}
              label="Applications"
            >
              <Sidebar.Item
                icon={TbFileCertificate}
                active={isActive('/applications')}
                onClick={() => navigate('/applications')}
              >
                Individual
              </Sidebar.Item>
              <Sidebar.Item
                style={{
                  display:
                    ['staff', 'officer', 'admin'].includes(
                      userdata?.role || '',
                    ) ||
                    (userdata?.role === 'user' && userdata?.barangayRole)
                      ? ''
                      : 'none',
                }}
                icon={TbBuildingCommunity}
                active={isActive('/barangay/applications')}
                onClick={() => navigate('/barangay/applications')}
              >
                Barangay
              </Sidebar.Item>
            </Sidebar.Collapse>

            <Sidebar.Item
              icon={HiOutlineCalendarDays}
              active={isActive('/calendar')}
              onClick={() => navigate('/calendar')}
            >
              Calendar
            </Sidebar.Item>
            <Sidebar.Item
              icon={TbUserShare}
              style={{
                display: ['staff', 'officer', 'admin'].includes(
                  userdata?.role || '',
                )
                  ? ''
                  : 'none',
              }}
              active={isActive('/beneficiaries')}
              onClick={() => navigate('/beneficiaries')}
            >
              Beneficiaries
            </Sidebar.Item>
            <Sidebar.Item
              icon={HiOutlineUsers}
              style={{ display: userdata?.role !== 'admin' ? 'none' : '' }}
              active={isActive('/users')}
              onClick={() => navigate('/users')}
            >
              Users
            </Sidebar.Item>
          </Sidebar.ItemGroup>
          <Sidebar.ItemGroup>
            <Sidebar.Item
              icon={HiOutlineCog6Tooth}
              active={isActive('/settings')}
              onClick={() => navigate('/settings')}
            >
              Settings
            </Sidebar.Item>
            <Sidebar.Item
              icon={HiOutlineUserCircle}
              active={isActive('/profile')}
              onClick={() => navigate('/profile')}
            >
              Profile
            </Sidebar.Item>
          </Sidebar.ItemGroup>
        </Sidebar.Items>
        <div className="flex flex-col space-y-4">
          <Sidebar.CTA>
            <div className="mb-3 flex items-center">
              <Badge color="warning">Reminder</Badge>
            </div>
            <div className="mb-3 text-sm text-cyan-900 dark:text-gray-400">
              Complete your profile before applying for a new application. This
              is a one-time setup only and needed for us to process your
              application.
            </div>
          </Sidebar.CTA>
          <Button color="blue" onClick={() => navigate('/logout')}>
            <CgLogOut className="mr-2 size-5" />
            Logout
          </Button>
        </div>
      </div>
    </Sidebar>
  );
}
