import { Badge, Button, Card } from 'flowbite-react';
import { UserIcon, SwatchIcon, BookOpenIcon } from '@heroicons/react/24/solid';
import { HiOutlineArrowRight } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import NonAuthLayout from '../layouts/NonAuthLayout';
import LinkIcon from '../components/buttons/LinkIcon';

export default function Home() {
  const navigate = useNavigate();
  return (
    <NonAuthLayout>
      <div className="container mx-auto flex flex-col items-center space-y-8 p-6 lg:pt-14">
        <Card className="w-full" horizontal>
          <div className="flex flex-col justify-around space-y-6 md:flex-row md:space-y-0">
            <div className="flex w-full flex-col items-center space-y-2 md:w-1/3">
              <p className="text-3xl font-extrabold leading-tight text-gray-900 dark:text-white">
                100+
              </p>
              <p className="text-center text-base font-normal text-gray-500 dark:text-white">
                Assistance Application Proccessed
              </p>
            </div>
            <div className="flex w-full flex-col items-center space-y-2 md:w-1/3">
              <p className="text-3xl font-extrabold leading-tight text-gray-900 dark:text-white">
                94
              </p>
              <p className="text-center text-base font-normal text-gray-500 dark:text-white">
                Recent Applications
              </p>
            </div>
            <div className="flex w-full flex-col items-center space-y-2 md:w-1/3">
              <p className="text-3xl font-extrabold leading-tight text-gray-900 dark:text-white">
                ₱100k+
              </p>
              <p className="text-center text-base font-normal text-gray-500 dark:text-white">
                Assistance Funds Released
              </p>
            </div>
          </div>
        </Card>
        <Card className="max-w-full">
          <Badge color={'blue'} className="w-fit" icon={UserIcon}>
            Users / Applicants
          </Badge>
          <h5 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white lg:text-5xl">
            Get the Help You Need
          </h5>
          <p className="font-normal text-gray-700 dark:text-gray-400 lg:text-xl">
            We’re here for you. Whether you’re facing a disaster or need support
            with daily needs, our platform connects you with the resources and
            assistance you deserve. Quick, easy, and reliable.
          </p>
          <Button
            color="blue"
            className="w-fit"
            onClick={() => navigate('/dashboard')}
          >
            Apply for Assistance
            <HiOutlineArrowRight className="ml-2 size-5" />
          </Button>
        </Card>
        <div className="flex flex-col space-y-8 lg:flex-row lg:justify-between lg:space-x-8 lg:space-y-0">
          <Card className="lg:w-full">
            <Badge color={'green'} className="w-fit" icon={SwatchIcon}>
              Admin / Officers
            </Badge>
            <h5 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white lg:text-5xl">
              Effortless Management
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400 lg:text-xl">
              Our platform is designed to streamline your workflow, making it
              easier to manage disaster relief and poverty alleviation
              initiatives.
            </p>
            <LinkIcon href="/dashboard" icon={HiOutlineArrowRight}>
              Manage Assistance Applications
            </LinkIcon>
          </Card>
          <Card className="lg:w-full">
            <Badge color={'purple'} className="w-fit" icon={BookOpenIcon}>
              Users/Applicants
            </Badge>
            <h5 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white lg:text-5xl">
              Reports Made Simple
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400 lg:text-xl">
              Say goodbye to tedious manual reporting. Our platform automates
              the process, allowing you to create detailed reports effortlessly
              and focus on what really matters.
            </p>
            <LinkIcon href="/dashboard" icon={HiOutlineArrowRight}>
              Generate Reports
            </LinkIcon>
          </Card>
        </div>
      </div>
    </NonAuthLayout>
  );
}
