import { format, parseISO } from 'date-fns';
import { ApplicationApiData } from './interface';

export function getSubmittedApplicationsThisMonth(
  applications: ApplicationApiData[],
) {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth(); // 0-based index (0 = January)
  const currentYear = currentDate.getFullYear();

  const totalApplications = applications.filter((app) => {
    const createdAt = new Date(app.created_at);
    return (
      createdAt.getMonth() === currentMonth &&
      createdAt.getFullYear() === currentYear
    );
  });

  return totalApplications.length;
}

export function getApprovedApplications(apiData: ApplicationApiData[]): number {
  return apiData.filter((application) => application.status === 'approved')
    .length;
}

export function getLatestApprovedApplicationUUID(
  apiData: ApplicationApiData[],
): string {
  const approvedApplications = apiData.filter(
    (application) => application.interview_at !== '',
  );
  if (approvedApplications.length === 0) {
    return 'No approved applications';
  }
  const latestApprovedApplication = approvedApplications.reduce(
    (latest, application) => {
      const applicationDate = parseISO(application.created_at);
      const latestDate = parseISO(latest.created_at);
      return applicationDate > latestDate ? application : latest;
    },
  );
  return latestApprovedApplication.uuid;
}

export function getReleasedApplications(apiData: ApplicationApiData[]): number {
  return apiData.filter((application) => application.released_at !== null)
    .length;
}

export function getLatestReleasedApplicationDate(
  apiData: ApplicationApiData[],
): string {
  const releasedApplications = apiData.filter(
    (application) => application.released_at !== null,
  );

  if (releasedApplications.length === 0) {
    return 'No released applications';
  }

  const latestReleasedApplication = releasedApplications.reduce(
    (latest, application) => {
      const applicationDate = parseISO(application.released_at!);
      const latestDate = parseISO(latest.released_at!);
      return applicationDate > latestDate ? application : latest;
    },
  );

  return format(
    parseISO(latestReleasedApplication.released_at!),
    'MMMM dd, yyyy',
  );
}

export function getMostRecentApplications(
  apiData: ApplicationApiData[],
): ApplicationApiData[] {
  const sortedApplications = apiData.sort((a, b) => {
    return parseISO(b.created_at).getTime() - parseISO(a.created_at).getTime();
  });

  return sortedApplications.slice(0, 3);
}

export function getApplicationsByAssistanceCategory(
  applications: ApplicationApiData[],
): Record<string, number> {
  const assistanceCount: Record<string, number> = {
    livelihood_assistance: 0,
    burial_assistance: 0,
    medical_assistance: 0,
    transportation_assistance: 0,
    philhealth_certification: 0,
    education_assistance: 0,
    others: 0,
  };

  applications.forEach((application) => {
    const category = application.assistance_category;

    if (category === 'Livelihood assistance') {
      assistanceCount['livelihood_assistance']++;
    } else if (category === 'Burial assistance') {
      assistanceCount['burial_assistance']++;
    } else if (category === 'Medical assistance') {
      assistanceCount['medical_assistance']++;
    } else if (category === 'Transportation assistance') {
      assistanceCount['transportation_assistance']++;
    } else if (category === 'Philhealth certification') {
      assistanceCount['philhealth_certification']++;
    } else if (category === 'Education assistance') {
      assistanceCount['education_assistance']++;
    } else {
      assistanceCount.others++;
    }
  });

  return assistanceCount;
}
