import { ReactNode } from 'react';
import { Card } from 'flowbite-react';

interface StatCardProps {
  title: string;
  body: string;
  subtitle?: string;
  color: string;
  icon: ReactNode;
}

export default function StatCard(props: StatCardProps) {
  return (
    <Card className="w-full">
      <div className=" flex w-full flex-row items-center justify-between space-x-4">
        <div className="flex flex-col space-y-1">
          <p className="text-3xl font-semibold text-gray-900 dark:text-slate-100">
            {props.body}
          </p>
          <p className="text-lg font-medium text-gray-700 dark:text-slate-300">
            {props.title}
          </p>
          <p className="text-xs font-medium text-gray-600 dark:text-slate-400">
            {props.subtitle}
          </p>
        </div>
        <div className="rounded-full p-4" style={{ background: props.color }}>
          <div>{props.icon}</div>
        </div>
      </div>
    </Card>
  );
}
