export function getStatusBadgeColor(status: string): string {
  let color: string;

  switch (status) {
    case 'submitted':
      color = 'info';
      break;
    case 'cancelled':
      color = 'gray';
      break;
    case 'interview':
      color = 'warning';
      break;
    case 'approved':
      color = 'success';
      break;
    case 'rejected':
      color = 'failure';
      break;
    case 'released':
      color = 'indigo';
      break;
    case 'completed':
      color = 'success';
      break;
    default:
      color = 'gray';
  }

  return color;
}

export function getUserBadgeColor(status: string): string {
  let color: string;

  switch (status) {
    case 'user':
      color = 'info';
      break;
    case 'staff':
      color = 'warning';
      break;
    case 'officer':
      color = 'success';
      break;
    case 'admin':
      color = 'purple';
      break;
    case 'system':
      color = 'failure';
      break;
    default:
      color = 'gray';
  }

  return color;
}

export function titleCase(str: string) {
  return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
}

export function snakeToTitleCase(snakeCaseStr: string): string {
  return snakeCaseStr
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export function snakeToUpperCase(snakeCaseStr: string): string {
  let formattedStr = snakeCaseStr.replace(/_/g, ' ');
  formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1);
  return formattedStr;
}

export function toSnakeCase(inputStr: string): string {
  inputStr = inputStr.replace(/[\s-]+/g, '_');
  const snakeCaseStr = inputStr.replace(/([a-z])([A-Z])/g, '$1_$2');
  return snakeCaseStr.toLowerCase();
}

export function getDateTimeAgo(dateString: string): string {
  const pastDate = new Date(dateString);
  const now = new Date();
  const differenceMs = now.getTime() - pastDate.getTime();

  const minutes = Math.floor(differenceMs / (1000 * 60));
  const hours = Math.floor(differenceMs / (1000 * 60 * 60));
  const days = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
  const weeks = Math.floor(differenceMs / (1000 * 60 * 60 * 24 * 7));

  if (weeks > 1) {
    return `${weeks} weeks ago`;
  } else if (weeks === 1) {
    return '1 week ago';
  } else if (days > 1) {
    return `${days} days ago`;
  } else if (days === 1) {
    return '1 day ago';
  } else if (hours > 1) {
    return `${hours} hours ago`;
  } else if (hours === 1) {
    return '1 hour ago';
  } else if (minutes > 1) {
    return `${minutes} minutes ago`;
  } else {
    return '1 minute ago';
  }
}

const colors = ['blue', 'red', 'yellow', 'green', 'purple'];

export function stringToColor(str: string): string {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const index = Math.abs(hash) % colors.length;
  return colors[index];
}
