import React from 'react';
import { Spinner } from 'flowbite-react';

export default function LoadingOverlay() {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black opacity-50">
      <Spinner color="light" />
    </div>
  );
}
