import { Label, Select } from 'flowbite-react';
import { DocumentTextIcon } from '@heroicons/react/24/solid';
import { BarangayApplicationFormData } from '../AddBarangayApplicationModal';
import { snakeToUpperCase } from '../../../common/utils';

interface ApplicationStepContentProps {
  applicationFormData: BarangayApplicationFormData;
}

export default function AddBarangayApplicationStep3({
  applicationFormData,
}: ApplicationStepContentProps) {
  return (
    <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
      <div className="grid md:col-span-2">
        <p className="text-base font-normal text-gray-500 dark:text-slate-300">
          Carefully review the applications below. Upon submission, application
          information cannot be changed and modified.
        </p>
      </div>
      <div className="md:col-span-2">
        <p className="text-base font-medium text-gray-800 dark:text-white">
          Application Information
        </p>
      </div>
      <div>
        <Label
          htmlFor="applicationType"
          value="Application type"
          className="mb-2"
        />
        <Select
          id="applicationType"
          name="applicationType"
          value={applicationFormData.applicationType}
          disabled
        >
          <option value={applicationFormData.applicationType}>
            {snakeToUpperCase(applicationFormData.applicationType)}
          </option>
        </Select>
      </div>
      <div>
        <Label
          htmlFor="assistanceCategory"
          value="Assistance category"
          className="mb-2"
        />
        <Select
          id="assistanceCategory"
          name="assistanceCategory"
          value={applicationFormData.assistanceCategory}
          disabled
        >
          <option value={applicationFormData.assistanceCategory}>
            {snakeToUpperCase(applicationFormData.assistanceCategory)}
          </option>
        </Select>
      </div>
      <div className="md:col-span-2">
        <p className="text-base font-medium text-gray-800 dark:text-white">
          Documents
        </p>
      </div>
      {applicationFormData.files &&
        Object.keys(applicationFormData.files).map((key) => (
          <div
            key={key}
            className="flex flex-col items-center justify-center space-y-2 px-4"
          >
            <p className="mb-2 w-full text-sm font-medium text-gray-800 dark:text-white">
              {snakeToUpperCase(key)}
            </p>
            <DocumentTextIcon className="size-16 fill-none stroke-gray-600 dark:stroke-slate-300" />
            <p className="text-center text-sm font-light text-gray-600 dark:text-slate-300">
              {applicationFormData.files[key].name}
            </p>
          </div>
        ))}
    </div>
  );
}
