import { useState } from 'react';
import { HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../../common/ToastContext';
import UserUpdateRoleModal from '../../modals/UserUpdateRoleModal';
import ConfirmModal from '../../modals/ConfirmModal';
import api from '../../../common/api';

interface ApplicationTableActionsProps {
  id: string;
  role: string;
}

export default function UserTableAction(props: ApplicationTableActionsProps) {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const updateRole = async (
    id: string,
    role: string,
    barangayRole: boolean,
  ) => {
    try {
      await api.patch(`/user/role/${id}?role=${role}`);
      await api.patch(
        `/user/toggle/barangay/${id}?value=${barangayRole ? '1' : '0'}`,
      );
      showToast('success', `User ID <${id}> role has been updated`);
    } catch (error: any) {
      console.log(error);
      if (error.response?.status === 401 || false) {
        showToast('error', 'Session timed out, please login again!');
        navigate('/logout');
        return;
      }
      showToast(
        'error',
        `Error updating user role: ${
          error.response?.data.detail[0].msg ||
          error.response?.data.detail ||
          'Unknown error'
        }`,
      );
    }
  };

  const deleteUser = async (id: string) => {
    try {
      await api.delete(`/user/${id}`);
      showToast('success', `User ID <${id}> has been deleted`);
    } catch (error: any) {
      console.log(error);
      if (error.response?.status === 401 || false) {
        showToast('error', 'Session timed out, please login again!');
        navigate('/logout');
        return;
      }
      showToast(
        'error',
        `Error deleted user: ${
          error.response?.data.detail[0].msg ||
          error.response?.data.detail ||
          'Unknown error'
        }`,
      );
    }
  };

  const handleUpdateUser = (role: string, barangayRole: boolean) => {
    updateRole(props.id, role, barangayRole);
    setShowUpdateModal(false);
  };

  const handleDeleteUser = () => {
    deleteUser(props.id);
    setShowConfirmDeleteModal(false);
  };

  return (
    <div className="flex flex-row space-x-4">
      <HiOutlinePencil
        size={20}
        className="cursor-pointer"
        title="Update user role"
        onClick={() => setShowUpdateModal(true)}
      />
      <HiOutlineTrash
        size={20}
        className="cursor-pointer"
        title="Delete user"
        onClick={() => setShowConfirmDeleteModal(true)}
      />
      <UserUpdateRoleModal
        show={showUpdateModal}
        onConfirm={(role, barangayRole) => handleUpdateUser(role, barangayRole)}
        onDismiss={() => setShowUpdateModal(false)}
        initialRole={props.role}
      />
      <ConfirmModal
        title="Confirm Cancellation"
        body="Are you sure you want to cancel this application? This action cannot be undone. Continue?"
        show={showConfirmDeleteModal}
        onCancel={() => setShowConfirmDeleteModal(false)}
        onClose={() => setShowConfirmDeleteModal(false)}
        onConfirm={handleDeleteUser}
      />
    </div>
  );
}
