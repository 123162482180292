import React, { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from './AuthContext';
import LoadingOverlay from '../components/overlays/LoadingOverlay';

interface ProtectedRouteProps {
  redirectPath?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  redirectPath = '/login',
}) => {
  const { check } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkIfAuthenticated = async () => {
      const authenticated = await check();
      setIsAuthenticated(authenticated);
      setLoading(false);
    };
    checkIfAuthenticated();
  });

  if (loading) {
    return <LoadingOverlay />;
  }

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
