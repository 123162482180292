import React, {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import api from './api';
import { UserFormData } from './interface';

interface Userdata {
  email: string;
  role: string;
  barangayRole: boolean;
  profile: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  barangay?: string;
  city?: string;
  province?: string;
  region?: string;
  birthdate?: string;
  birthplace?: string;
  sex?: string;
  status?: string;
  religion?: string;
  contact?: string;
  education?: string;
  philhealth?: string;
  occupation?: string;
  income?: string;
}

interface AuthContextType {
  isAuthenticated: boolean;
  userdata: Userdata | null;
  register: (
    username: string,
    password: string,
    confirmPassword: string,
  ) => Promise<{
    success: boolean;
    detail: string;
  }>;
  login: (
    username: string,
    password: string,
  ) => Promise<{ success: boolean; detail: string; token: string }>;
  logout: () => void;
  check: () => Promise<boolean>;
  checkProfile: () => Promise<boolean>;
  token: string | null;
  updateUserdata: (data: UserFormData) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);
  const [userdata, setUserdata] = useState<Userdata | null>(null);

  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      setToken(savedToken);
    }
  }, []);

  const register = async (
    username: string,
    password: string,
    confirmPassword: string,
  ) => {
    const data = new URLSearchParams();
    data.append('username', username);
    data.append('password', password);
    data.append('confirm_password', confirmPassword);
    const response = {
      success: false,
      detail: '',
    };
    try {
      await api.post('/register', data);
      response.success = true;
      response.detail = 'User registered successfully';
    } catch (errorResponse: any) {
      console.log(errorResponse);
      response.detail =
        errorResponse.response.data.detail || 'User registration failed';
    }
    return response;
  };

  const login = async (username: string, password: string) => {
    const data = new URLSearchParams();
    data.append('username', username);
    data.append('password', password);
    const response = {
      success: false,
      detail: '',
      token: '',
    };
    try {
      const apiResponse = await api.post('/login', data);
      response.success = true;
      response.detail = 'Login successfully';
      response.token = apiResponse.data?.access_token;
    } catch (error: any) {
      response.detail =
        error.response?.data.detail[0].msg ||
        error.response?.data.detail ||
        'Unknown error';
      setIsAuthenticated(false);
      return response;
    }
    localStorage.setItem('token', response.token);
    setToken(response.token);
    getUserdata();
    setIsAuthenticated(true);
    return response;
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setToken(null);
    setUserdata(null);
  };

  const check = async () => {
    try {
      if (!localStorage.getItem('token')) {
        return false;
      }
      const response = await api.get('/checkauth');
      if (response.status === 200) {
        setIsAuthenticated(true);
        if (!userdata) {
          getUserdata();
        }
        return true;
      }
      setIsAuthenticated(false);
      return false;
    } catch (error) {
      console.log(error);
      setIsAuthenticated(false);
      setUserdata(null);
      return false;
    }
  };

  const getUserdata = async () => {
    try {
      const apiResponse = await api.get('/me');
      if (apiResponse.status === 200) {
        const data: Userdata = {
          email: apiResponse.data?.email || '',
          role: apiResponse.data?.role || '',
          barangayRole: apiResponse.data?.barangay_role || false,
          profile: apiResponse.data?.profile || '',
          firstName: apiResponse.data?.first_name || '',
          middleName: apiResponse.data?.middle_name || '',
          lastName: apiResponse.data?.last_name || '',
          barangay: apiResponse.data?.barangay || '',
          city: apiResponse.data?.city || '',
          province: apiResponse.data?.province || '',
          region: apiResponse.data?.region || '',
          birthdate: apiResponse.data?.birthdate || '',
          birthplace: apiResponse.data?.birthplace || '',
          sex: apiResponse.data?.sex || '',
          status: apiResponse.data?.status || '',
          religion: apiResponse.data?.religion || '',
          contact: apiResponse.data?.contact || '',
          education: apiResponse.data?.education || '',
          philhealth: apiResponse.data?.philhealth || '',
          occupation: apiResponse.data?.occupation || '',
          income: apiResponse.data?.income || '',
        };
        setUserdata(data);
      }
    } catch (error) {}
  };

  const updateUserdata = (data: UserFormData) => {
    setUserdata((prevData) => {
      if (!prevData) return null;
      console.log(data);
      const updatedData: Userdata = {
        ...prevData,
        firstName: data.first_name || prevData.firstName,
        middleName: data.middle_name || prevData.middleName,
        lastName: data.last_name || prevData.lastName,
        profile: data.profile || prevData.profile,
        birthdate: data.birthdate || prevData.birthdate,
        birthplace: data.birthplace || prevData.birthplace,
        sex: data.sex || prevData.sex,
        status: data.status || prevData.status,
        religion: data.religion || prevData.religion,
        contact: data.contact || prevData.contact,
        education: data.education || prevData.education,
        philhealth: data.philhealth || prevData.philhealth,
        occupation: data.occupation || prevData.occupation,
        income: data.income || prevData.income,
      };

      return updatedData;
    });
  };

  const checkProfile = async () => {
    try {
      const apiResponse = await api.get('/checkprofile');
      const complete = apiResponse.data?.complete || false;
      return complete;
    } catch (errorResponse: any) {
      return false;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userdata,
        register,
        login,
        logout,
        check,
        checkProfile,
        token,
        updateUserdata,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
