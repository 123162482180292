import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { Button, Modal, Label, TextInput, Datepicker } from 'flowbite-react';
import { UserGroupIcon } from '@heroicons/react/24/solid';
import { useToast } from '../../common/ToastContext';
import TimePicker from '../TimePicker';
import { useNavigate } from 'react-router-dom';
import api from '../../common/api';

interface InterviewModalProps {
  uuid: string;
  show: boolean;
  onDismiss: () => void;
  onSubmitSuccess: () => void;
  onSubmitFailure: () => void;
}

function InterviewModal(props: InterviewModalProps) {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [showModal, setShowModal] = useState(false);
  const [location, setLocation] = useState('');
  const [date, setDate] = useState<string | Date | null>(
    new Date().toISOString().split('T')[0],
  );
  const [time, setTime] = useState('09:00');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setShowModal(props.show);
  }, [props.show]);

  const handleDateChange = (selectedDate: string | Date | null) => {
    if (selectedDate) {
      setDate(
        typeof selectedDate === 'string'
          ? selectedDate
          : format(selectedDate, 'yyyy-MM-dd'),
      );
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const formData = new FormData();
    const dateTime = `${date}T${time}:00Z`;
    formData.append('interview_location', location);
    formData.append('interview_at', dateTime);

    // console.log(Array.from(formData.entries()));

    try {
      setIsLoading(true);
      await api.post(`/interview/${props.uuid}`, formData);
      setLocation('');
      setDate(new Date().toISOString().split('T')[0]);
      setTime('09:00');
    } catch (error: any) {
      console.log(error);
      if (error.response?.status === 401 || false) {
        showToast('error', 'Session timed out, please login again!');
        navigate('/logout');
        return;
      }
      showToast(
        'error',
        `Error creating schedule: ${
          error.response?.data.detail[0].msg ||
          error.response?.data.detail ||
          'Unknown error'
        }`,
      );
      setLocation('');
      setDate(new Date().toISOString().split('T')[0]);
      setTime('09:00');
      setIsLoading(false);
      props.onSubmitFailure();
      return;
    }
    try {
      await api.patch(`/application/status/${props.uuid}?new_status=interview`);
      setIsLoading(false);
      showToast(
        'success',
        'Application interview schedule updated successfully!',
      );
      props.onSubmitSuccess();
    } catch (error: any) {
      console.log(error);
      if (error.response?.status === 401 || false) {
        showToast('error', 'Session timed out, please login again!');
        navigate('/logout');
        return;
      }
      showToast(
        'error',
        `Error creating schedule: ${
          error.response?.data.detail[0].msg ||
          error.response?.data.detail ||
          'Unknown error'
        }`,
      );
      setIsLoading(false);
      props.onSubmitFailure();
    }
  };

  return (
    <Modal show={showModal} size="lg" onClose={props.onDismiss} popup>
      <Modal.Header className="px-6 py-4">Schedule an interview</Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-y-8">
          <div className="grid">
            <p className="text-base font-normal text-gray-700 dark:text-white">
              Have you reviewed the application yet? Schedule an interview now
              to keep things moving forward.
            </p>
          </div>
          <div>
            <Label
              htmlFor="interviewLocation"
              value="Interview Location"
              className="mb-2"
            />
            <TextInput
              id="interviewLocation"
              name="interviewLocation"
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              required
            />
          </div>
          <div>
            <Label
              htmlFor="interviewDate"
              value="Interview Date"
              className="mb-2"
            />
            <Datepicker
              id="interviewDate"
              name="interviewDate"
              onSelectedDateChanged={(e) => handleDateChange(e)}
              onChange={(e) => handleDateChange(e.target.value)}
              required
            />
          </div>
          <div>
            <Label
              htmlFor="interviewTime"
              value="Interview Time"
              className="mb-2"
            />
            <TimePicker
              onChange={(value) => {
                setTime(value);
              }}
            />
          </div>
          <div className="grid justify-items-center">
            <Button color="blue" type="submit" isProcessing={isLoading}>
              <UserGroupIcon className="mr-2 size-5" />
              Set interview schedule
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default InterviewModal;
