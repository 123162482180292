import { Label, Select, Textarea } from 'flowbite-react';
import { BarangayApplicationFormData } from '../AddBarangayApplicationModal';

interface ApplicationStepContentProps {
  applicationFormData: BarangayApplicationFormData;
  handleInputChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => void;
}

export default function AddBarangayApplicationStep1({
  applicationFormData,
  handleInputChange,
}: ApplicationStepContentProps) {
  return (
    <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
      <div className="grid md:col-span-2">
        <p className="text-base font-normal text-gray-500 dark:text-slate-300">
          Submit your assistance application quickly and securely. Provide the
          necessary details to begin the process and ensure your request is
          processed efficiently.
        </p>
      </div>
      <div>
        <Label
          htmlFor="applicationType"
          value="Application type"
          className="mb-2"
        />
        <Select
          id="applicationType"
          name="applicationType"
          required
          value={applicationFormData.applicationType}
          onChange={handleInputChange}
        >
          <option value="" disabled>
            Select application type
          </option>
          <option value="Counselling">Counselling</option>
          <option value="Financial or material">Financial or material</option>
          <option value="Legal Services">Legal Services</option>
          <option value="Rehabilitation Services">
            Rehabilitation Services
          </option>
          <option value="Temporary Shelter">Temporary Shelter</option>
        </Select>
      </div>
      <div>
        <Label
          htmlFor="assistanceCategory"
          value="Assistance category"
          className="mb-2"
        />
        <Select
          id="assistanceCategory"
          name="assistanceCategory"
          required
          value={applicationFormData.assistanceCategory}
          onChange={handleInputChange}
        >
          <option value="" disabled>
            Select assistance category
          </option>
          <optgroup label="Financial Assistance">
            <option value="Burial assistance">Burial assistance</option>
            <option value="Disaster rehabilitation">
              Disaster rehabilitation
            </option>
            <option value="Education assistance">Education assistance</option>
            <option value="Financial assistance">Financial assistance</option>
            <option value="Food assistance">Food assistance</option>
            <option value="Livelihood assistance">Livelihood assistance</option>
            <option value="Medical assistance">Medical assistance</option>
            <option value="Transportation assistance">
              Transportation assistance
            </option>
            <option value="Other support services">
              Other support services
            </option>
          </optgroup>
          <optgroup label="Material Assistance">
            <option value="Assistive device & Technologies">
              Assistive device & Technologies
            </option>
            <option value="Food pack">Food pack</option>
            <option value="Hot meal">Hot meal</option>
            <option value="Hygiene and sleeping kits">
              Hygiene and sleeping kits
            </option>
            <option value="Used clothing">Used clothing</option>
            <option value="Other food item">Other food item</option>
          </optgroup>
          <optgroup label="Psychosocial support">
            <option value="Psychological first aid (PFA)">
              Psychological first aid (PFA)
            </option>
            <option value="Social work counselling">
              Social work counselling
            </option>
          </optgroup>
          <optgroup label="Others">
            <option value="Philhealth certification">
              Philhealth certification
            </option>
            <option value="SPED">SPED</option>
          </optgroup>
        </Select>
      </div>
      <div className="md:col-span-2">
        <Label htmlFor="purpose" value="Purpose" className="mb-2" />
        <Textarea
          id="purpose"
          name="purpose"
          placeholder="Brief description describing the purpose of the application"
          rows={4}
          value={applicationFormData.purpose}
          onChange={handleInputChange}
          required
        />
      </div>
    </div>
  );
}
