export interface Notification {
  profile: string;
  title: string;
  body: string;
  time: string;
}

export interface NotifcationPopoverProps {
  notifications: Notification[];
}

export default function NotificationPopover(props: NotifcationPopoverProps) {
  return (
    <div
      id="dropdownMenu"
      className="absolute -top-3 right-0 z-[1000] mt-2 block max-h-[500px] w-[410px] min-w-full overflow-auto rounded-lg bg-white py-4 shadow-lg dark:bg-gray-800"
    >
      <p className="mx-4 text-base font-semibold text-gray-800 dark:text-white">
        Notifications
      </p>
      {props.notifications.length <= 0 ? (
        <p className="mx-4 mt-4 text-center text-sm font-normal text-gray-600 dark:text-white">
          No notifications to show
        </p>
      ) : (
        <ul className="divide-y">
          {props.notifications.map(
            (notification: Notification, index: number) => (
              <li
                key={index}
                className="flex cursor-pointer items-center p-4 hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                <img
                  src={`/profiles/${notification.profile}.jpg`}
                  alt="Profile"
                  className="size-12 shrink-0 rounded-full"
                />

                <div className="ml-6">
                  <h3 className="text-sm font-semibold text-[#333] dark:text-slate-100">
                    {notification.title}
                  </h3>
                  <p className="mt-2 text-xs text-gray-500 dark:text-slate-200">
                    {notification.body}
                  </p>
                  <p className="mt-2 text-xs leading-3 text-blue-600">
                    {notification.time}
                  </p>
                </div>
              </li>
            ),
          )}
        </ul>
      )}
    </div>
  );
}
