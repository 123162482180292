import { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import { Card, Spinner } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../common/ToastContext';
import AuthLayout from '../layouts/AuthLayout';
import BeneficiaryTable, {
  BeneficiaryDetail,
} from '../components/tables/BeneficiaryTableFlowbite';
import { BeneficiaryApiData } from '../common/interface';
import api from '../common/api';

export default function Beneficiaries() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState<BeneficiaryDetail[]>([]);

  useEffect(() => {
    async function getApplications() {
      setIsLoading(true);
      let apiResponse = { data: [] };
      try {
        apiResponse = await api.get('/beneficiaries');
        const apiData = apiResponse.data as BeneficiaryApiData[];
        const beneficiaries: BeneficiaryDetail[] = apiData.map(
          (beneficiary, index) => ({
            firstName: beneficiary.first_name,
            middleName: beneficiary.middle_name,
            lastName: beneficiary.last_name,
            name: `${beneficiary.first_name} ${beneficiary.last_name}`,
            barangay: beneficiary.barangay,
            city: beneficiary.city,
            province: beneficiary.province,
            region: beneficiary.region,
            birthdate: format(parseISO(beneficiary.birthdate), 'MMMM dd, yyyy'),
            birthplace: beneficiary.birthplace,
            sex: beneficiary.sex,
            status: beneficiary.status,
            contact: beneficiary.contact,
            occupation: beneficiary.status,
            income: parseInt(beneficiary.income),
          }),
        );
        setRows(beneficiaries);
        setIsLoading(false);
      } catch (error: any) {
        console.log(error);
        if (error.response?.status === 401 || false) {
          showToast('error', 'Session timed out, please login again!');
          navigate('/logout');
          return;
        }
        showToast(
          'error',
          `Error retrieving applications: ${
            error.response?.data.detail[0].msg ||
            error.response?.data.detail ||
            'Unknown error'
          }`,
        );
        setIsLoading(false);
      }
    }
    getApplications();
  }, [showToast, navigate]);

  return (
    <AuthLayout>
      <div
        style={{ display: isLoading ? 'flex' : 'none' }}
        className="flex size-full items-center justify-center"
      >
        <Spinner size="xl" />
      </div>
      <Card style={{ display: isLoading ? 'none' : 'flex' }} className="w-full">
        <p className="text-lg font-semibold text-gray-900 dark:text-slate-100">
          Beneficiaries
        </p>
        <BeneficiaryTable data={rows} />
      </Card>
    </AuthLayout>
  );
}
