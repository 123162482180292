import { useState, useEffect } from 'react';
import {
  Badge,
  Button,
  Checkbox,
  Dropdown,
  Label,
  Pagination,
  Table,
  TextInput,
} from 'flowbite-react';
import {
  HiOutlineSearch,
  HiOutlineFilter,
  HiOutlineSortAscending,
  HiOutlineSortDescending,
} from 'react-icons/hi';
import {
  TbClearAll,
  TbSortAscending2,
  TbSortDescending2,
} from 'react-icons/tb';
import { useAuth } from '../../common/AuthContext';
import ApplicationTableActions from './application/ApplicationTableActions';
import { getStatusBadgeColor } from '../../common/utils';

export interface ApplicationDetail {
  uuid: string;
  applicationType: string;
  assistanceCategory: string;
  amount: number;
  financialMode: string;
  assistanceSource: string;
  purpose: string;
  status: string;
  interviewLocation: string;
  interviewDatetime: string;
  releasedDatetime: string;
  appliedDatetime: string;
  applicant: string;
}

interface ApplicationTableProps {
  data: ApplicationDetail[];
}

export default function ApplicationTable(props: ApplicationTableProps) {
  const { userdata } = useAuth();
  const [data, setData] = useState<ApplicationDetail[]>(props.data);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [sortCategory, setSortCategory] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [showReleasedOnly, setShowReleasedOnly] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const onPageChange = (page: number) => setCurrentPage(page);

  const handleFilter = (category: string, filterString: string) => {
    setFilterCategory(category);
    setFilter(filterString);
  };

  const handleSort = (category: string, order: string) => {
    setSortCategory(category);
    setSortOrder(order);
  };

  const parseCustomDate = (dateString: string) => {
    return new Date(Date.parse(dateString));
  };

  useEffect(() => {
    const filterApplications = () => {
      let filteredData = [...props.data];

      if (search) {
        filteredData = filteredData.filter((application) =>
          application.uuid.toLowerCase().includes(search.toLowerCase()),
        );
      }

      if (filter && filterCategory) {
        filteredData = filteredData.filter((application) =>
          application[filterCategory as keyof ApplicationDetail]
            .toString()
            .toLowerCase()
            .includes(filter.toLowerCase()),
        );
      }

      if (showReleasedOnly) {
        filteredData = filteredData.filter(
          (application) => application.releasedDatetime !== '',
        );
      }

      if (sortCategory) {
        filteredData.sort((a, b) => {
          let aValue = a[sortCategory as keyof ApplicationDetail];
          let bValue = b[sortCategory as keyof ApplicationDetail];

          if (sortCategory === 'date') {
            aValue = parseCustomDate(a.appliedDatetime).getTime();
            bValue = parseCustomDate(b.appliedDatetime).getTime();
          }

          if (sortOrder === 'asc') {
            return aValue > bValue ? 1 : -1;
          } else {
            return aValue < bValue ? 1 : -1;
          }
        });
      }

      setData(filteredData);
    };

    filterApplications();
  }, [
    props.data,
    search,
    filter,
    filterCategory,
    sortCategory,
    sortOrder,
    showReleasedOnly,
  ]);

  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const totalPages = Math.ceil(data.length / itemsPerPage);

  return (
    <>
      <div className="relative mt-2 flex w-full flex-row items-center justify-between">
        <TextInput
          id="search"
          type="text"
          icon={HiOutlineSearch}
          placeholder="Search application number"
          className="w-48 md:w-72"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="relative flex flex-row items-center justify-start space-x-2 lg:space-x-6">
          <div className="flex items-center gap-2">
            <Checkbox
              id="releasedOnly"
              color="blue"
              onChange={(e) => setShowReleasedOnly(e.target.checked)}
            />
            <Label htmlFor="releasedOnly" className="flex">
              Show released only
            </Label>
          </div>
          <Dropdown
            label=""
            placement="left-start"
            className="max-h-72 w-80 overflow-auto text-left"
            dismissOnClick={true}
            renderTrigger={() => (
              <Button color="gray">
                <HiOutlineFilter className="md:mr-3" size={20} />
                <p className="hidden lg:block">Filter by</p>
              </Button>
            )}
          >
            <Dropdown.Header>Filter by</Dropdown.Header>
            <Dropdown.Item
              icon={TbClearAll}
              onClick={() => handleFilter('', '')}
            >
              Clear filters
            </Dropdown.Item>
            <p className="px-4 py-2 text-sm font-semibold text-gray-800 dark:text-slate-200">
              Status
            </p>
            <Dropdown.Item onClick={() => handleFilter('status', 'submitted')}>
              Submitted
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleFilter('status', 'interview')}>
              Interview
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleFilter('status', 'approved')}>
              Approved
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleFilter('status', 'rejected')}>
              Rejected
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleFilter('status', 'released')}>
              Released
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleFilter('status', 'completed')}>
              Completed
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleFilter('status', 'cancelled')}>
              Cancelled
            </Dropdown.Item>
            <p className="px-4 py-2 text-sm font-semibold text-gray-800 dark:text-slate-200">
              Category
            </p>
            <Dropdown.Item
              onClick={() =>
                handleFilter('assistanceCategory', 'Burial assistance')
              }
            >
              Burial assistance
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                handleFilter('assistanceCategory', 'Disaster rehabilitation')
              }
            >
              Disaster rehabilitation
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                handleFilter('assistanceCategory', 'Education assistance')
              }
            >
              Education assistance
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                handleFilter('assistanceCategory', 'Financial assistance')
              }
            >
              Financial assistance
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                handleFilter('assistanceCategory', 'Food assistance')
              }
            >
              Food assistance
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                handleFilter('assistanceCategory', 'Livelihood assistance')
              }
            >
              Livelihood assistance
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                handleFilter('assistanceCategory', 'Medical assistance')
              }
            >
              Medical assistance
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                handleFilter('assistanceCategory', 'Transportation assistance')
              }
            >
              Transportation assistance
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                handleFilter('assistanceCategory', 'Other support services')
              }
            >
              Other support services
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                handleFilter(
                  'assistanceCategory',
                  'Assistive device & Technologies',
                )
              }
            >
              Assistive device & Technologies
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleFilter('assistanceCategory', 'Food pack')}
            >
              Food pack
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleFilter('assistanceCategory', 'Hot meal')}
            >
              Hot meal
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                handleFilter('assistanceCategory', 'Hygiene and sleeping kits')
              }
            >
              Hygiene and sleeping kits
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                handleFilter('assistanceCategory', 'Used clothing')
              }
            >
              Used clothing
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                handleFilter('assistanceCategory', 'Other food item')
              }
            >
              Other food item
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                handleFilter(
                  'assistanceCategory',
                  'Psychological first aid (PFA)',
                )
              }
            >
              Psychological first aid (PFA)
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                handleFilter('assistanceCategory', 'Social work counselling')
              }
            >
              Social work counselling
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                handleFilter('assistanceCategory', 'Philhealth certification')
              }
            >
              Philhealth certification
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleFilter('assistanceCategory', 'SPED')}
            >
              SPED
            </Dropdown.Item>
          </Dropdown>
          <Dropdown
            label=""
            placement="left-start"
            className="max-h-72 w-80 overflow-auto"
            dismissOnClick={true}
            renderTrigger={() => (
              <Button color="gray">
                {sortOrder === 'asc' ? (
                  <HiOutlineSortAscending className="md:mr-3" size={20} />
                ) : (
                  <HiOutlineSortDescending className="md:mr-3" size={20} />
                )}
                <p className="hidden lg:block">Sort by</p>
              </Button>
            )}
          >
            <Dropdown.Header>Sort by</Dropdown.Header>
            <Dropdown.Item icon={TbClearAll} onClick={() => handleSort('', '')}>
              Clear sorts
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('uuid', 'asc')}>
              Application Number (Ascending)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('uuid', 'desc')}>
              Application Number (Descending)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('date', 'asc')}>
              Application Date (Ascending)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSort('date', 'desc')}>
              Application Date (Descending)
            </Dropdown.Item>
          </Dropdown>
        </div>
      </div>
      <div className="overflow-x-auto">
        <Table>
          <Table.Head>
            <Table.HeadCell>
              <div className="flex flex-row">
                <span>Application Number</span>
                {sortCategory === 'uuid' && sortOrder === 'asc' ? (
                  <TbSortAscending2 className="ml-2" size={16} />
                ) : sortCategory === 'uuid' && sortOrder === 'desc' ? (
                  <TbSortDescending2 className="ml-2" size={16} />
                ) : (
                  ''
                )}
              </div>
            </Table.HeadCell>
            <Table.HeadCell>
              <div className="flex flex-row">
                <span>Date</span>
                {sortCategory === 'date' && sortOrder === 'asc' ? (
                  <TbSortAscending2 className="ml-2" size={16} />
                ) : sortCategory === 'date' && sortOrder === 'desc' ? (
                  <TbSortDescending2 className="ml-2" size={16} />
                ) : (
                  ''
                )}
              </div>
            </Table.HeadCell>
            <Table.HeadCell>Category</Table.HeadCell>
            <Table.HeadCell>Status</Table.HeadCell>

            <Table.HeadCell>
              <span className="sr-only">Edit</span>
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {paginatedData.map((application, index) => (
              <Table.Row
                key={index}
                className="bg-white dark:border-gray-700 dark:bg-gray-800"
              >
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {application.uuid}
                </Table.Cell>
                <Table.Cell>{application.appliedDatetime}</Table.Cell>
                <Table.Cell>{application.assistanceCategory}</Table.Cell>
                <Table.Cell>
                  <Badge
                    color={getStatusBadgeColor(application.status)}
                    className="w-20 items-center justify-center py-1.5"
                  >
                    {application.status}
                  </Badge>
                </Table.Cell>
                <Table.Cell>
                  <ApplicationTableActions
                    role={userdata?.role ?? ''}
                    detail={application}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <div className="flex items-center justify-center">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
          showIcons
        />
      </div>
    </>
  );
}
