import { useState, useEffect } from 'react';
import { Modal, Button, Table } from 'flowbite-react';
import { BarangayApplicationDetail } from '../tables/BarangayApplicationTableFlowbite';

interface BarangayApplicationDetailModalProps {
  show: boolean;
  onClose: () => void;
  applicationDetail: BarangayApplicationDetail;
}

export default function BarangayApplicationDetailModal(
  props: BarangayApplicationDetailModalProps,
) {
  const [showModal, setShowModal] = useState(props.show);

  useEffect(() => {
    setShowModal(props.show);
  }, [props.show]);

  return (
    <Modal show={showModal} size="lg" onClose={props.onClose}>
      <Modal.Header>Application Details</Modal.Header>
      <Modal.Body>
        <Table hoverable={true}>
          <tbody>
            <tr>
              <td className="pr-4 text-right font-semibold">
                Application Type:
              </td>
              <td>{props.applicationDetail.applicationType}</td>
            </tr>
            <tr>
              <td className="pr-4 text-right font-semibold">
                Assistance Category:
              </td>
              <td>{props.applicationDetail.assistanceCategory}</td>
            </tr>
            <tr>
              <td className="pr-4 text-right font-semibold">Amount:</td>
              <td>
                {props.applicationDetail.amount < 0
                  ? 'Amount not set'
                  : props.applicationDetail.amount}
              </td>
            </tr>
            <tr>
              <td className="pr-4 text-right font-semibold">Financial Mode:</td>
              <td>{props.applicationDetail.financialMode}</td>
            </tr>
            <tr>
              <td className="pr-4 text-right font-semibold">
                Assistance Source:
              </td>
              <td>{props.applicationDetail.assistanceSource}</td>
            </tr>
            <tr>
              <td className="pr-4 text-right font-semibold">Purpose:</td>
              <td>{props.applicationDetail.purpose}</td>
            </tr>
            <tr>
              <td className="pr-4 text-right font-semibold">Status:</td>
              <td>{props.applicationDetail.status}</td>
            </tr>
            <tr>
              <td className="pr-4 text-right font-semibold">Released:</td>
              <td>{props.applicationDetail.releasedDatetime}</td>
            </tr>
            <tr>
              <td className="pr-4 text-right font-semibold">Applied:</td>
              <td>{props.applicationDetail.appliedDatetime}</td>
            </tr>
            <tr>
              <td className="pr-4 text-right font-semibold">Applicant:</td>
              <td>{props.applicationDetail.applicant}</td>
            </tr>
          </tbody>
        </Table>
        <div className="flex items-center justify-center">
          <Button onClick={props.onClose} className="mt-4 px-6" color="blue">
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
