import { useState, useEffect, useRef } from 'react';
import { Button, Modal } from 'flowbite-react';
import SignatureCanvas from 'react-signature-canvas';

interface UpdateSignatureModalProps {
  show: boolean;
  signature: string | null;
  onDismiss: () => void;
  onSignatureUpdate: (value: string) => void;
}

export default function UpdateSignatureModal(props: UpdateSignatureModalProps) {
  const [showModal, setShowModal] = useState(props.show);
  const sigCanvas = useRef<SignatureCanvas>(null);

  const clearSignature = () => {
    sigCanvas.current?.clear();
  };

  const saveSignature = () => {
    if (sigCanvas.current?.isEmpty()) {
      alert('Please provide a signature first.');
    } else {
      const dataURL = sigCanvas.current?.toDataURL();
      console.log(dataURL);
      props.onSignatureUpdate(dataURL ?? '');
    }
  };

  useEffect(() => {
    setShowModal(props.show);
    sigCanvas.current?.fromDataURL(props.signature ?? '');
  }, [props.show, props.signature]);

  return (
    <Modal show={showModal} size="xl" onClose={() => props.onDismiss()} popup>
      <Modal.Header className="px-6 py-4">Update Signature</Modal.Header>
      <Modal.Body>
        <SignatureCanvas
          ref={sigCanvas}
          penColor="black"
          canvasProps={{
            className: 'w-full h-full border border-gray-300',
          }}
        />

        <div className="mt-4 flex justify-between">
          <Button onClick={clearSignature} color="red">
            Clear
          </Button>
          <Button onClick={saveSignature} color="green">
            Save
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
