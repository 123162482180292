import { ReactNode } from 'react';
import NonAuthNavbar from '../components/navigations/NonAuthNavbar';

interface NonAuthLayoutProps {
  children?: ReactNode;
}

export default function NonAuthLayout({ children }: NonAuthLayoutProps) {
  return (
    <main className="flex h-screen flex-col dark:bg-gray-800">
      <NonAuthNavbar />
      {children}
    </main>
  );
}
