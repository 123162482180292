import { useState, useEffect } from 'react';
import { Button, Modal } from 'flowbite-react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

interface ConfirmModalProps {
  show: boolean;
  title: string;
  body: string;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
}

export default function ConfirmModal(props: ConfirmModalProps) {
  const [showModal, setShowModal] = useState<boolean>(props.show);

  useEffect(() => {
    setShowModal(props.show);
  }, [props.show]);

  return (
    <Modal show={showModal} size="md" onClose={props.onClose} popup>
      <Modal.Header />
      <Modal.Body>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
            <ExclamationTriangleIcon className="size-6 text-red-600"></ExclamationTriangleIcon>
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3
              className="text-base font-semibold leading-6 text-gray-900"
              id="modal-title"
            >
              {props.title}
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{props.body}</p>
            </div>
          </div>
        </div>
        <div className="mt-2 flex flex-row justify-center space-x-4 px-6 py-3 lg:justify-end">
          <Button color="gray" onClick={props.onCancel}>
            Cancel
          </Button>
          <Button color="failure" onClick={props.onConfirm}>
            Proceed
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
