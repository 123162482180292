import { useState, useEffect } from 'react';
import { Button, Modal } from 'flowbite-react';

interface TermsAndConditionsModalProps {
  show: boolean;
  onClose: () => void;
}

export default function TermsAndConditionsModal(
  props: TermsAndConditionsModalProps,
) {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(props.show);
  }, [props.show]);

  return (
    <Modal show={showModal} size="lg" popup={true} onClose={props.onClose}>
      <Modal.Header />
      <Modal.Body>
        <h3 className="text-xl font-medium text-gray-900">
          Terms and Conditions
        </h3>
        <div className="mt-4 max-h-96 space-y-4 overflow-y-auto text-sm text-gray-500">
          <p>
            <strong>Last updated:</strong> October 5, 2024
          </p>
          <p>
            Welcome to the MSWDash platform (the "Platform"). By using this
            Platform, you agree to the collection, use, and processing of your
            personal data as outlined in these terms and conditions. Please read
            the following carefully before submitting your application.
          </p>

          <h4 className="mt-4 text-lg font-semibold">
            1. Data Collection and Use
          </h4>
          <p>
            When you use this Platform to apply for cash assistance, you provide
            personal data that may include your full name, contact information,
            identification details, financial records, and other relevant
            information. This information is collected to process your
            application, verify eligibility, and determine the appropriate
            assistance you may qualify for.
          </p>
          <p>Your data may also be used for:</p>
          <ul className="list-inside list-disc pl-4">
            <li>Communicating updates about your application status.</li>
            <li>Compliance with legal and regulatory obligations.</li>
            <li>
              Statistical reporting for program improvement and policy-making.
            </li>
          </ul>

          <h4 className="mt-4 text-lg font-semibold">
            2. Sharing of Information
          </h4>
          <p>
            Your personal data may be shared with authorized DSWD personnel,
            partner agencies, and other government bodies responsible for
            verifying and processing your application. We ensure that these
            entities handle your information securely and solely for the
            purposes stated here.
          </p>

          <h4 className="mt-4 text-lg font-semibold">
            3. Security and Protection of Data
          </h4>
          <p>
            We are committed to ensuring that your personal information is
            secure. We have implemented appropriate technical and organizational
            measures to protect your data against unauthorized access, loss, or
            disclosure.
          </p>

          <h4 className="mt-4 text-lg font-semibold">
            4. Consent to Process Data
          </h4>
          <p>
            By submitting your application, you give consent for the DSWD to
            collect, store, and process your personal data in accordance with
            the applicable laws and regulations. You also acknowledge that your
            data may be shared with partner agencies and service providers to
            facilitate the processing and approval of your cash assistance
            application.
          </p>

          <h4 className="mt-4 text-lg font-semibold">
            5. Beneficiary Information
          </h4>
          <p>
            In cases where your application is being filed on behalf of a
            beneficiary, you confirm that you have obtained their consent to
            provide their personal information for the purpose of processing the
            cash assistance.
          </p>

          <h4 className="mt-4 text-lg font-semibold">6. Retention of Data</h4>
          <p>
            Your personal data will be retained for as long as necessary to
            fulfill the purposes outlined in these terms, or as required by law.
          </p>

          <h4 className="mt-4 text-lg font-semibold">
            7. Rights Over Your Data
          </h4>
          <p>
            You have the right to:
            <ul className="list-inside list-disc pl-4">
              <li>Access the personal data we hold about you.</li>
              <li>Request corrections to inaccurate data.</li>
              <li>
                Withdraw your consent at any time, subject to legal obligations.
              </li>
            </ul>
          </p>

          <h4 className="mt-4 text-lg font-semibold">
            8. Changes to Terms and Conditions
          </h4>
          <p>
            We reserve the right to modify or update these terms and conditions
            at any time. Any significant changes will be communicated to you
            through this Platform.
          </p>
        </div>
        <Button onClick={props.onClose} className="mt-4">
          Close
        </Button>
      </Modal.Body>
    </Modal>
  );
}
