import { useState, useEffect } from 'react';
import { Checkbox, Modal, Label, Select, Button } from 'flowbite-react';
import { PencilIcon } from '@heroicons/react/24/solid';
import { UserRole } from '../../common/enums';

interface UserUpdateRoleModalProps {
  show: boolean;
  initialRole: string;
  onDismiss: () => void;
  onConfirm: (role: string, barangayRole: boolean) => void;
}

function UserUpdateRoleModal(props: UserUpdateRoleModalProps) {
  const [showModal, setShowModal] = useState(props.show);
  const [barangayRole, setBarangayRole] = useState(false);
  const [selectedRole, setSelectedRole] = useState(
    props.initialRole !== UserRole.admin ? props.initialRole : 'user',
  );

  useEffect(() => {
    setShowModal(props.show);
  }, [props.show]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setBarangayRole(isChecked);
  };

  return (
    <Modal show={showModal} size="2xl" onClose={() => props.onDismiss()} popup>
      <Modal.Header className="px-6 py-4">Update User Role</Modal.Header>
      <Modal.Body>
        <div className="grid grid-cols-1 gap-4">
          <div>
            <p className="text-base font-normal text-gray-700 dark:text-white">
              Pick the role you would like to assign to the user. Each role
              comes with specific permissions:
            </p>
            <ul className="ml-5 list-disc text-base font-normal text-gray-700 dark:text-white">
              <li>
                <span className="font-semibold">User: </span>
                Basic access to features and services. Can apply for
                applications.
              </li>
              <li>
                <span className="font-semibold">Staff: </span>
                Access to additional tools for managing content and application
                interactions.
              </li>
              <li>
                <span className="font-semibold">Officer: </span>
                Access to additional application settings such as application
                approval or rejection.
              </li>
            </ul>
          </div>
          <div>
            <Label htmlFor="role" value="Role" className="mb-2" />
            <Select
              id="role"
              name="role"
              required
              value={selectedRole}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setSelectedRole(e.target.value)
              }
            >
              <option value="" disabled>
                Select role
              </option>
              <option value="user">User</option>
              <option value="staff">Staff</option>
              <option value="officer">Officer</option>
            </Select>
            <div className="mt-2">
              <Checkbox
                id="barangayRole"
                name="barangayRole"
                onChange={handleCheckboxChange}
              />
              <Label
                htmlFor="barangayRole"
                value="Enable barangay permission"
                className="mb-2 ml-2"
              />
            </div>
          </div>
          <div className="grid justify-items-center">
            <Button
              color="blue"
              type="button"
              onClick={() => props.onConfirm(selectedRole, barangayRole)}
            >
              <PencilIcon className="mr-2 size-5" />
              Update user role
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default UserUpdateRoleModal;
