import { Dropdown, Button } from 'flowbite-react';
import { BellAlertIcon } from '@heroicons/react/24/solid';
import NotificationPopover, {
  NotifcationPopoverProps,
} from '../popovers/NotificationPopover';

export default function NotificationButton(props: NotifcationPopoverProps) {
  return (
    <div className={`relative mx-auto w-max md:flex`}>
      <Dropdown
        label=""
        dismissOnClick={false}
        className="hidden py-0 md:flex"
        renderTrigger={() => (
          <Button
            color="none"
            className="dark-hover:bg-none hidden px-0 hover:bg-gray-200 dark:bg-none md:flex"
          >
            <BellAlertIcon className="size-5 fill-gray-500 stroke-gray-500" />
          </Button>
        )}
      >
        <NotificationPopover notifications={props.notifications} />
      </Dropdown>
      <Button
        color="none"
        className="dark-hover:bg-none flex px-0 hover:bg-gray-200 dark:bg-none md:hidden"
      >
        <BellAlertIcon className="size-5 fill-gray-500 stroke-gray-500" />
      </Button>
    </div>
  );
}
