import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './common/AuthContext';
import { ToastProvider } from './common/ToastContext';
import { AppThemeProvider } from './common/ThemeContext';
import ToastManager from './components/ToastManager';
import ProtectedRoute from './common/ProtectedRoute';
import UnprotectedRoute from './common/UnprotectedRoute';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import LogoutPage from './pages/LogoutPage';
import Dashboard from './pages/Dashboard';
import Applications from './pages/Applications';
import BarangayApplications from './pages/BarangayApplications';
import Calendar from './pages/Calendar';
import Beneficiaries from './pages/Beneficiaries';
import Reports from './pages/Reports';
import UsersPage from './pages/UsersPage';
import ProfilePage from './pages/ProfilePage';
import NotFound from './pages/NotFound';

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppThemeProvider>
          <ToastProvider>
            <ToastManager />
            <Routes>
              <Route element={<UnprotectedRoute />}>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
              </Route>
              <Route element={<ProtectedRoute />}>
                <Route path="/logout" element={<LogoutPage />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/applications" element={<Applications />} />
                <Route
                  path="/barangay/applications"
                  element={<BarangayApplications />}
                />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/beneficiaries" element={<Beneficiaries />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/users" element={<UsersPage />} />
                <Route path="/profile" element={<ProfilePage />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ToastProvider>
        </AppThemeProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
