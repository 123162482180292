import { useState } from 'react';
import { TbListDetails } from 'react-icons/tb';
import { BeneficiaryDetail } from '../BeneficiaryTableFlowbite';
import BeneficiaryDetailModal from '../../modals/BeneficiaryDetailModal';

interface BeneficiaryTableActionsProps {
  detail: BeneficiaryDetail;
}

export default function BeneficiaryTableActions(
  props: BeneficiaryTableActionsProps,
) {
  const [showDetailModal, setShowDetailModal] = useState(false);

  return (
    <div className="flex flex-row space-x-4">
      <TbListDetails
        size={20}
        className="cursor-pointer"
        title="View beneficiary details"
        onClick={() => setShowDetailModal(true)}
      />
      <BeneficiaryDetailModal
        show={showDetailModal}
        onClose={() => setShowDetailModal(false)}
        beneficiaryDetail={props.detail}
      />
    </div>
  );
}
