import { useState, useEffect } from 'react';
import { Button, Card, Popover } from 'flowbite-react';
import { PencilIcon } from '@heroicons/react/24/solid';
import { HiOutlineInformationCircle } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../common/ToastContext';
import UpdateSignatureModal from '../modals/UpdateSignatureModal';
import api from '../../common/api';

export default function SignatureCard() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [imageURL, setImageURL] = useState<string | null>(null);
  const [showSignatureModal, setShowSignatureModal] = useState(false);

  useEffect(() => {
    async function getSignature() {
      try {
        const apiResponse = await api.get('/me/signature');
        const signature = apiResponse.data?.signature;
        if (signature != null) {
          setImageURL(`data:image/png;base64,${signature}`);
        }
      } catch (error: any) {
        console.log(error);
        if (error.response?.status === 401 || false) {
          showToast('error', 'Session timed out, please login again!');
          navigate('/logout');
          return;
        }
        showToast(
          'error',
          `Error retrieving signature: ${
            error.response?.data.detail[0].msg ||
            error.response?.data.detail ||
            'Unknown error'
          }`,
        );
      }
    }
    getSignature();
  }, [showToast, navigate]);

  const handleSignatureUpdate = async (value: string) => {
    try {
      await api.post(
        '/me/signature',
        JSON.stringify({
          signature: value.replace('data:image/png;base64,', ''),
        }),
      );
      setImageURL(value);
      showToast('success', 'Signature updated successfully!');
      setShowSignatureModal(false);
    } catch (error: any) {
      console.log(error);
      if (error.response?.status === 401 || false) {
        showToast('error', 'Session timed out, please login again!');
        navigate('/logout');
        return;
      }
      showToast(
        'error',
        `Error retrieving signature: ${
          error.response?.data.detail[0].msg ||
          error.response?.data.detail ||
          'Unknown error'
        }`,
      );
    }
  };

  return (
    <>
      <Card>
        <div className="flex flex-row items-center justify-between">
          <p className="text-base font-bold text-gray-800 dark:text-slate-200">
            Signature
          </p>
          <div className="relative flex">
            <Popover
              placement="right"
              content={
                <div className="w-64 px-3 py-2 text-sm text-gray-500 dark:text-gray-400">
                  Adding a signature automatically signs the application
                  document involved in your actions
                </div>
              }
            >
              <div className="pr-2">
                <HiOutlineInformationCircle
                  size={24}
                  className="cursor-pointer stroke-gray-800 dark:stroke-slate-200"
                />
              </div>
            </Popover>
          </div>
        </div>

        {imageURL !== null ? (
          <div className="mt-4">
            <img
              src={imageURL ?? ''}
              alt="Signature"
              className="mt-2 h-44 w-full border border-gray-300"
            />
          </div>
        ) : (
          <div className="mt-2 h-44 w-full border border-gray-300"></div>
        )}

        <div className="flex items-center justify-center md:col-span-2">
          <Button
            color="blue"
            type="button"
            onClick={() => setShowSignatureModal(true)}
          >
            <PencilIcon className="mr-2 size-5" />
            Update signature
          </Button>
        </div>
      </Card>
      <UpdateSignatureModal
        show={showSignatureModal}
        signature={imageURL}
        onDismiss={() => setShowSignatureModal(false)}
        onSignatureUpdate={handleSignatureUpdate}
      />
    </>
  );
}
