import { useState, useEffect } from 'react';
import { Modal } from 'flowbite-react';

interface ScheduleDetailModalProps {
  show: boolean;
  onClose: () => void;
  title: string;
  content: string;
}

export default function ScheduleDetailModal(props: ScheduleDetailModalProps) {
  const [showModal, setShowModal] = useState(props.show);

  useEffect(() => {
    setShowModal(props.show);
  }, [props.show]);

  return (
    <Modal show={showModal} size="md" onClose={props.onClose}>
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Body>
        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
          {props.content}
        </p>
      </Modal.Body>
    </Modal>
  );
}
