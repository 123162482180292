import { useState } from 'react';
import {
  HiOutlineCash,
  HiOutlineChatAlt2,
  HiOutlineDocumentRemove,
  HiOutlineEye,
  HiOutlinePencil,
  HiOutlineTrash,
} from 'react-icons/hi';
import { AiOutlineFileDone } from 'react-icons/ai';
import { FaRegThumbsUp, FaRegThumbsDown } from 'react-icons/fa6';
import { TbListDetails } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../../common/ToastContext';
import ApplicationDetailModal from '../../modals/ApplicationDetailModal';
import ConfirmModal from '../../modals/ConfirmModal';
import InterviewModal from '../../modals/InterviewModal';
import ApproveModal from '../../modals/ApproveModal';
import ReleaseModal from '../../modals/ReleaseModal';
import { ApplicationDetail } from '../ApplicationTableFlowbite';
import api from '../../../common/api';

const useViewPdf = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const viewPdf = async (uuid: string) => {
    try {
      const response = await api.get(`/download/${uuid}?combined=true`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/pdf',
        },
      });
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, '_blank');
      setTimeout(() => URL.revokeObjectURL(pdfUrl), 100);
    } catch (error: any) {
      console.error('Error fetching PDF:', error);
      if (error.response?.status === 401) {
        showToast('error', 'Session timed out, please login again!');
        navigate('/logout');
        return;
      }
    }
  };
  return { viewPdf };
};

const useDeleteApplication = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const deleteApplication = async (uuid: string) => {
    try {
      await api.delete(`/application/${uuid}`);
      showToast('success', `Application <${uuid}> has been deleted`);
    } catch (error: any) {
      console.error('Error fetching PDF:', error);
      if (error.response?.status === 401) {
        showToast('error', 'Session timed out, please login again!');
        navigate('/logout');
        return;
      }
    }
  };
  return { deleteApplication };
};

const useUpdateApplicationStatus = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();

  const updateApplicationStatus = async (uuid: string, status: string) => {
    try {
      await api.patch(`/application/status/${uuid}?new_status=${status}`);
      showToast('success', `Application <${uuid}> has been ${status}`);
    } catch (error: any) {
      console.log(error);
      if (error.response?.status === 401 || false) {
        showToast('error', 'Session timed out, please login again!');
        navigate('/logout');
        return;
      }
      showToast(
        'error',
        `Error updating application: ${
          error.response?.data.detail[0].msg ||
          error.response?.data.detail ||
          'Unknown error'
        }`,
      );
    }
  };
  return { updateApplicationStatus };
};

interface ApplicationTableActionsProps {
  detail: ApplicationDetail;
}

export function UserApplicationTableAction(
  props: ApplicationTableActionsProps,
) {
  const { viewPdf } = useViewPdf();
  const { updateApplicationStatus } = useUpdateApplicationStatus();
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleCancelApplication = () => {
    updateApplicationStatus(props.detail.uuid, 'cancelled');
    setShowConfirmModal(false);
  };

  return (
    <div className="flex flex-row space-x-4">
      <TbListDetails
        size={20}
        className="cursor-pointer"
        title="View application details"
        onClick={() => setShowDetailModal(true)}
      />
      <HiOutlineEye
        size={20}
        className="cursor-pointer"
        title="View application"
        onClick={() => viewPdf(props.detail.uuid)}
      />
      <HiOutlineDocumentRemove
        size={20}
        style={{ display: props.detail.status === 'cancelled' ? 'none' : '' }}
        className="cursor-pointer"
        title="Cancel application"
        onClick={() => setShowConfirmModal(true)}
      />
      <ApplicationDetailModal
        show={showDetailModal}
        onClose={() => setShowDetailModal(false)}
        applicationDetail={props.detail}
      />
      <ConfirmModal
        title="Confirm Cancellation"
        body="Are you sure you want to cancel this application? This action cannot be undone. Continue?"
        show={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleCancelApplication}
      />
    </div>
  );
}

export function StaffApplicationTableAction(
  props: ApplicationTableActionsProps,
) {
  const { viewPdf } = useViewPdf();
  const { updateApplicationStatus } = useUpdateApplicationStatus();
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showInterviewModal, setShowInterviewModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showConfirmRejectModal, setShowConfirmRejectModal] = useState(false);
  const [showConfirmCompleteModal, setShowConfirmCompleteModal] =
    useState(false);

  const handleRejectApplication = () => {
    updateApplicationStatus(props.detail.uuid, 'rejected');
    setShowConfirmRejectModal(false);
  };

  const handleCompleteApplication = () => {
    updateApplicationStatus(props.detail.uuid, 'completed');
    setShowConfirmCompleteModal(false);
  };

  return (
    <div className="flex flex-row space-x-4">
      <TbListDetails
        size={20}
        className="cursor-pointer"
        title="View application details"
        onClick={() => setShowDetailModal(true)}
      />
      <HiOutlineEye
        size={20}
        className="cursor-pointer"
        title="View application"
        onClick={() => viewPdf(props.detail.uuid)}
      />
      <HiOutlineChatAlt2
        size={20}
        style={{ display: props.detail.status !== 'submitted' ? 'none' : '' }}
        className="cursor-pointer"
        title="Schedule interview"
        onClick={() => setShowInterviewModal(true)}
      />
      <FaRegThumbsUp
        size={20}
        style={{ display: props.detail.status !== 'interview' ? 'none' : '' }}
        className="cursor-pointer"
        title="Approve application"
        onClick={() => setShowApproveModal(true)}
      />
      <FaRegThumbsDown
        size={20}
        style={{ display: props.detail.status !== 'interview' ? 'none' : '' }}
        className="cursor-pointer"
        title="Reject application"
        onClick={() => setShowConfirmRejectModal(true)}
      />
      <AiOutlineFileDone
        size={20}
        style={{ display: props.detail.status !== 'released' ? 'none' : '' }}
        className="cursor-pointer"
        title="Set as completed"
        onClick={() => setShowConfirmCompleteModal(true)}
      />
      <ApplicationDetailModal
        show={showDetailModal}
        onClose={() => setShowDetailModal(false)}
        applicationDetail={props.detail}
      />
      <InterviewModal
        uuid={props.detail.uuid}
        show={showInterviewModal}
        onDismiss={() => setShowInterviewModal(false)}
        onSubmitFailure={() => setShowInterviewModal(false)}
        onSubmitSuccess={() => setShowInterviewModal(false)}
      />
      <ApproveModal
        uuid={props.detail.uuid}
        show={showApproveModal}
        onDismiss={() => setShowApproveModal(false)}
        onSubmitSuccess={() => setShowApproveModal(false)}
        onSubmitFailure={() => setShowApproveModal(false)}
      />
      <ConfirmModal
        title="Confirm Rejection"
        body="Are you sure you want to reject this application? This action cannot be undone. Continue?"
        show={showConfirmRejectModal}
        onCancel={() => setShowConfirmRejectModal(false)}
        onClose={() => setShowConfirmRejectModal(false)}
        onConfirm={handleRejectApplication}
      />
      <ConfirmModal
        title="Set as completed"
        body="Are you sure you want to set this application as completed? This action cannot be undone. Continue?"
        show={showConfirmCompleteModal}
        onCancel={() => setShowConfirmCompleteModal(false)}
        onClose={() => setShowConfirmCompleteModal(false)}
        onConfirm={handleCompleteApplication}
      />
    </div>
  );
}

export function OfficerApplicationTableAction(
  props: ApplicationTableActionsProps,
) {
  const { viewPdf } = useViewPdf();
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showReleaseModal, setShowReleaseModal] = useState(false);

  return (
    <div className="flex flex-row space-x-4">
      <TbListDetails
        size={20}
        className="cursor-pointer"
        title="View application details"
        onClick={() => setShowDetailModal(true)}
      />
      <HiOutlineEye
        size={20}
        className="cursor-pointer"
        title="View application"
        onClick={() => viewPdf(props.detail.uuid)}
      />
      <HiOutlineCash
        size={20}
        style={{
          display: props.detail.status === 'approved' ? 'block' : 'none',
        }}
        className="cursor-pointer"
        title="Release application"
        onClick={() => setShowReleaseModal(true)}
      />
      <ApplicationDetailModal
        show={showDetailModal}
        onClose={() => setShowDetailModal(false)}
        applicationDetail={props.detail}
      />
      <ReleaseModal
        uuid={props.detail.uuid}
        show={showReleaseModal}
        onDismiss={() => setShowReleaseModal(false)}
        onSubmitSuccess={() => setShowReleaseModal(false)}
        onSubmitFailure={() => setShowReleaseModal(false)}
      />
    </div>
  );
}

export function AdminApplicationTableAction(
  props: ApplicationTableActionsProps,
) {
  const { viewPdf } = useViewPdf();
  const { deleteApplication } = useDeleteApplication();
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const handleDeleteApplication = () => {
    deleteApplication(props.detail.uuid);
    setShowConfirmDeleteModal(false);
  };

  return (
    <div className="flex flex-row space-x-4">
      <TbListDetails
        size={20}
        className="cursor-pointer"
        title="View application details"
        onClick={() => setShowDetailModal(true)}
      />
      <HiOutlineEye
        size={20}
        className="cursor-pointer"
        title="View application"
        onClick={() => viewPdf(props.detail.uuid)}
      />
      <HiOutlinePencil
        size={20}
        className="cursor-pointer"
        title="Edit application"
      />
      <HiOutlineTrash
        size={20}
        className="cursor-pointer"
        title="Delete application"
        onClick={() => setShowConfirmDeleteModal(true)}
      />
      <ApplicationDetailModal
        show={showDetailModal}
        onClose={() => setShowDetailModal(false)}
        applicationDetail={props.detail}
      />
      <ConfirmModal
        title="Delete application"
        body="Are you sure you want to delete this application? This action cannot be undone. Continue?"
        show={showConfirmDeleteModal}
        onCancel={() => setShowConfirmDeleteModal(false)}
        onClose={() => setShowConfirmDeleteModal(false)}
        onConfirm={handleDeleteApplication}
      />
    </div>
  );
}

interface RoleApplicationTableActionProps extends ApplicationTableActionsProps {
  role: string;
}

export default function ApplicationTableActions({
  role,
  detail,
}: RoleApplicationTableActionProps) {
  switch (role) {
    case 'user':
      return <UserApplicationTableAction detail={detail} />;
    case 'staff':
      return <StaffApplicationTableAction detail={detail} />;
    case 'officer':
      return <OfficerApplicationTableAction detail={detail} />;
    case 'admin':
      return <AdminApplicationTableAction detail={detail} />;
    default:
      return null;
  }
}
