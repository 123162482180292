import { useState } from 'react';
import ScheduleDetailModal from '../modals/ScheduleDetailModal';

interface ScheduleChipProps {
  color: string;
  agenda: string;
  title: string;
  content: string;
}

export default function ScheduleChip(props: ScheduleChipProps) {
  const [showDetailModal, setShowDetailModal] = useState(false);

  return (
    <>
      <div
        onClick={() => setShowDetailModal(true)}
        className={`mt-1 cursor-pointer overflow-hidden rounded-lg border px-2 py-1 ${
          props.color === 'blue'
            ? 'border-blue-200 bg-blue-100 text-blue-800'
            : props.color === 'red'
            ? 'border-red-200 bg-red-100 text-red-800'
            : props.color === 'yellow'
            ? 'border-yellow-200 bg-yellow-100 text-yellow-800'
            : props.color === 'green'
            ? 'border-green-200 bg-green-100 text-green-800'
            : props.color === 'purple'
            ? 'border-purple-200 bg-purple-100 text-purple-800'
            : ''
        }`}
      >
        <p className="text-sm leading-tight">{props.agenda}</p>
      </div>
      <ScheduleDetailModal
        show={showDetailModal}
        onClose={() => setShowDetailModal(false)}
        title={props.title}
        content={props.content}
      />
    </>
  );
}
