enum UserRole {
  user = 'user',
  staff = 'staff',
  officer = 'officer',
  admin = 'admin',
}

enum ApplicationStatus {
  submitted = 'submitted',
  cancelled = 'cancelled',
  interview = 'interview',
  approved = 'approved',
  rejected = 'rejected',
  released = 'released',
  completed = 'completed',
  deleted = 'deleted',
}

enum ApiAction {
  get = 'get',
  post = 'post',
  patch = 'patch',
  delete = 'delete',
}

export { UserRole, ApplicationStatus, ApiAction };
